import React from "react";
import { useFormValidation } from "../../hooks";
import { Form } from "../../library";
import { EquipmentPrescription } from "../../types";
import RemainingCharacters from "../RemainingCharacters";

type Props = {
  setEquipmentPrescription: (value: EquipmentPrescription) => void;
  equipmentPrescription: EquipmentPrescription;
};

const MAX_LENGTH = 1000;

const AssessmentEquipmentPrescription: React.FC<Props> = ({
  equipmentPrescription,
  setEquipmentPrescription,
}) => {
  const { register, handleBlur, watch } = useFormValidation<{
    equipmentPrescription: string;
  }>({ values: { equipmentPrescription } });

  const values = watch();

  return (
    <Form
      onBlur={handleBlur(({ equipmentPrescription }) =>
        setEquipmentPrescription(equipmentPrescription)
      )}
    >
      <Form.Field>
        <Form.Label>Equipment prescription:</Form.Label>
        <Form.TextareaUncontrolled
          {...register("equipmentPrescription")}
          maxLength={MAX_LENGTH}
          rows={20}
        />
        <Form.Help>
          <RemainingCharacters
            max={MAX_LENGTH}
            value={values.equipmentPrescription}
          />
        </Form.Help>
      </Form.Field>
    </Form>
  );
};

export default AssessmentEquipmentPrescription;
