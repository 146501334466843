import { useInfiniteGetAssessmentGroupsQuery } from "../../graphql/generated";
import { queryClient } from "../../ReactQuery";
import { AssessmentGroup, patchRemoteAssessmentGroup } from "../../types";

type Props = {
  search?: string | null;
  limit?: number;
};

const useGetAssessmentGroups = ({ search, limit = 50 }: Props = {}) => {
  const {
    data,
    isLoading,
    isPaused,
    isFetching,
    error,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteGetAssessmentGroupsQuery(
    "nextToken", // doesn't actually do anything in generated function
    { limit, search: search?.toLowerCase() },
    {
      getNextPageParam: ({ getAssessmentGroups: { nextToken } }) =>
        nextToken ? { nextToken } : undefined,
    }
  );

  const assessmentGroups =
    data?.pages.flatMap((a) =>
      a.getAssessmentGroups.items.map(patchRemoteAssessmentGroup)
    ) || [];

  const newGroups =
    queryClient.getQueryData<AssessmentGroup[]>(["NewAssessmentGroups"]) || [];

  return {
    assessmentGroups: [...newGroups, ...assessmentGroups],
    state: {
      error,
      loading: isLoading && isFetching,
      paused: isPaused,
    },
    loadMore: hasNextPage ? fetchNextPage : undefined,
  };
};

export default useGetAssessmentGroups;
