import React from "react";

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import colors from "./colors";
import { AssessmentGroup } from "../../types";
import { formatDateTime } from "../../utils/date";

const resultsStyles = StyleSheet.create({
  table: {
    fontSize: 8,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
  },
  lastRow: {
    borderTop: `0.5 solid ${colors.lightGrey}`,
  },
  tableTitleRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "extrabold",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lighterGrey,
    fontSize: 10,
  },
  col1: {
    width: 110,
    padding: 5,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lighterGrey,
    fontWeight: "extrabold",
  },
  col2: {
    width: 600,
    padding: 5,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
});

const TableRow: React.FC<{ col1: string; col2: string }> = ({ col1, col2 }) => {
  return (
    <View style={resultsStyles.tableRow}>
      <Text style={resultsStyles.col1}>{col1}</Text>
      <Text style={resultsStyles.col2}>{col2}</Text>
    </View>
  );
};

type Props = {
  assessmentGroup: AssessmentGroup;
};

const AssessmentGroupDetailsPdf: React.FC<Props> = ({ assessmentGroup }) => {
  return (
    <View style={resultsStyles.table}>
      <TableRow col1="Patient:" col2={assessmentGroup.patient} />
      <TableRow col1="Description:" col2={assessmentGroup.description} />
      <TableRow col1="Therapist:" col2={assessmentGroup.therapist} />
      <TableRow col1="Location:" col2={assessmentGroup.location} />
      <TableRow
        col1="Assessment date:"
        col2={formatDateTime(assessmentGroup.assessmentDate)}
      />
      <TableRow col1="Notes:" col2={assessmentGroup.notes} />

      <View style={resultsStyles.lastRow}></View>
    </View>
  );
};

export default AssessmentGroupDetailsPdf;
