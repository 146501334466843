import React from "react";
import { useFormValidation } from "../../hooks";
import { Form } from "../../library";
import { SetAssessmentGroupNotesFn } from "../../types";
import RemainingCharacters from "../RemainingCharacters";

type Props = {
  setNotes: SetAssessmentGroupNotesFn;
  notes: string;
};

const AssessmentGroupDetailsForm: React.FC<Props> = ({ setNotes, notes }) => {
  const { register, handleBlur, watch } = useFormValidation<{ notes: string }>({
    values: { notes },
  });

  const notesValue = watch("notes");

  return (
    <Form onBlur={handleBlur(({ notes }) => setNotes(notes))}>
      <Form.Field>
        <Form.Label>Notes</Form.Label>
        <Form.TextareaUncontrolled
          maxLength={1000}
          rows={20}
          {...register("notes")}
        />
        <Form.Help>
          <RemainingCharacters max={1000} value={notesValue} />
        </Form.Help>
      </Form.Field>
    </Form>
  );
};

export default AssessmentGroupDetailsForm;
