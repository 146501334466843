import { useCallback, useState } from "react";

type UseModal = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

type Props = {
  open?: boolean;
  onClose?: () => void;
};

const useModal = ({ open = false, onClose }: Props = {}): UseModal => {
  const [isModalOpen, setIsModalOpen] = useState(open);

  const openModal = useCallback(() => setIsModalOpen(true), [setIsModalOpen]);
  const closeModal = useCallback(() => {
    onClose?.();
    setIsModalOpen(false);
  }, [onClose, setIsModalOpen]);

  return { isModalOpen, openModal, closeModal };
};

export default useModal;
