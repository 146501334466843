import React, { useCallback } from "react";
import { Banner, Modal, useModal } from "../../library";
import {
  AssessmentCriteria,
  CreateNewAssessmentFn,
  RemoteOperationState,
} from "../../types";
import AssessmentCriteriaSelection, {
  useAssessmentCriteriaSelection,
} from "../AssessmentCriteriaSelection";

type Props = {
  isModalOpen: boolean;
  closeModal: () => void;
  createAssessment: CreateNewAssessmentFn;
  createAssessmentState?: RemoteOperationState;
  criterion: AssessmentCriteria | null;
  setCriterion: (criterion: AssessmentCriteria | null) => void;
  details: string;
  setDetails: (details: string) => void;
};

export const useNewAssessmentModal = (onClose?: () => void) => {
  const { criterion, setCriterion, details, setDetails, reset } =
    useAssessmentCriteriaSelection();

  const { isModalOpen, closeModal, openModal } = useModal({
    onClose: () => {
      reset();
      onClose?.();
    },
  });

  const openModalWithDefault = useCallback(
    (criterion: AssessmentCriteria | null = null) => {
      setCriterion(criterion);
      openModal();
    },
    [openModal, setCriterion]
  );

  return {
    setCriterion,
    criterion,
    details,
    setDetails,
    isModalOpen,
    openModal: openModalWithDefault,
    closeModal,
  };
};

const NewAssessmentModal: React.FC<Props> = ({
  closeModal,
  isModalOpen,
  createAssessment,
  createAssessmentState,
  criterion,
  setCriterion,
  details,
  setDetails,
}) => {
  return (
    <Modal
      aria-label="New assessment"
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <Modal.Header>
        New assessment {criterion ? ` » ${criterion}` : ""}
      </Modal.Header>
      {createAssessmentState?.error && (
        <Banner>There was an error creating the assessment</Banner>
      )}
      <Modal.Body>
        <AssessmentCriteriaSelection.Options
          criterion={criterion}
          setCriterion={setCriterion}
        />
        <AssessmentCriteriaSelection.Details
          criterion={criterion}
          setDetails={setDetails}
        />
      </Modal.Body>
      <Modal.Footer>
        <AssessmentCriteriaSelection.Button.Back
          criterion={criterion}
          setCriterion={setCriterion}
        />

        <AssessmentCriteriaSelection.Button.Select
          disabled={createAssessmentState?.loading}
          criterion={criterion}
          details={details}
          onClick={createAssessment}
        >
          {createAssessmentState?.loading ? "Creating..." : "Start"}
        </AssessmentCriteriaSelection.Button.Select>
      </Modal.Footer>
    </Modal>
  );
};

export default NewAssessmentModal;
