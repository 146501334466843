import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
} from "react-router-dom";

import Authenticator from "./screens/auth/Authenticator";
import AccountScreen from "./screens/auth/AccountScreen";
import SignedOutScreen from "./screens/auth/SignedOutScreen";
import AssessmentScreen from "./screens/remote/Assessment";
import LocalAssessmentScreen from "./screens/local/Assessment";
import AssessmentsScreen from "./screens/remote/Assessments";

import { getCurrentUser, signOut } from "./utils/auth";
import ErrorBoundary from "./components/ErrorBoundary";
import AssessmentGroupScreen from "./screens/remote/AssessmentGroup";
import HelpScreen from "./screens/pages/Help";

const redirectIfNotUser = async () => {
  const user = await getCurrentUser();
  if (!user) return redirect("/");
  return null;
};

const redirectIfUser = async () => {
  const user = await getCurrentUser();
  if (user) return redirect("/assessments");
  return null;
};

const doSignOut = async () => {
  await signOut();
  return null;
};

// Configure nested routes with JSX
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<ErrorBoundary />}>
      <Route path="sign-in" element={<Authenticator />} />
      <Route path="sign-out" element={<SignedOutScreen />} loader={doSignOut} />
      <Route loader={redirectIfUser}>
        <Route index element={<LocalAssessmentScreen />} />
      </Route>
      <Route loader={redirectIfNotUser}>
        <Route path="/assessments" element={<AssessmentsScreen />} />
        <Route path="/assessment/:id" element={<AssessmentScreen />} />
        <Route path="/assessment-group/:id" element={<AssessmentGroupScreen />}>
          <Route index element={<AssessmentGroupScreen.Details />} />
          <Route
            path="recommendations"
            element={<AssessmentGroupScreen.Recommendations />}
          />
          <Route
            path="equipment"
            element={<AssessmentGroupScreen.EquipmentPrescription />}
          ></Route>
        </Route>
        <Route path="account" element={<AccountScreen />} />
      </Route>
      <Route path="/help" element={<HelpScreen />} />
    </Route>
  )
);

export default router;
