import { useCallback, useState } from "react";

function usePartialState<S>(
  initialState: S | (() => S)
): [S, React.Dispatch<Partial<S>>] {
  const [state, setState] = useState<S>(initialState);

  const setPartialState = useCallback(
    (values: Partial<S>) =>
      setState((current) => ({
        ...current,
        ...values,
      })),
    []
  );

  return [state, setPartialState];
}

export default usePartialState;
