import { useCallback } from "react";
import { ulid } from "ulid";
import { useMutation } from "@tanstack/react-query";
import {
  AssessmentGroup,
  CreateNewAssessmentGroupFn,
  defaultAssessment,
  defaultAssessmentGroup,
  RemoteOperationState,
} from "../../types";
import {
  CreateAssessmentGroupMutation,
  useCreateAssessmentGroupMutation,
} from "../../graphql/generated";
import useCreateAssessment from "./useCreateAssessment";

type UseCreateAssessmentGroup = {
  createAssessmentGroup: CreateNewAssessmentGroupFn;
  createAssessmentGroupState: RemoteOperationState;
};

const useCreateAssessmentGroup = (): UseCreateAssessmentGroup => {
  const {
    mutate,
    error,
    isLoading: loading,
    reset,
  } = useMutation<CreateAssessmentGroupMutation, unknown, AssessmentGroup>(
    useCreateAssessmentGroupMutation.getKey()
  );

  const { createAssessment } = useCreateAssessment();

  const createAssessmentGroup: CreateNewAssessmentGroupFn = useCallback(
    async (assessmentGroup, assessmentCriterion) => {
      const assessmentGroupId = ulid();

      const assessment = defaultAssessment(
        `${assessmentGroupId}_${ulid()}`,
        assessmentGroupId,
        assessmentCriterion
      );

      const newAssessmentGroup = defaultAssessmentGroup(
        assessmentGroupId,
        assessmentGroup.patient,
        assessmentGroup.description,
        {
          assessments: [assessment], // Can we get the backend to just create this!?
        }
      );

      mutate(newAssessmentGroup);

      createAssessment(assessment);
    },
    [mutate, createAssessment]
  );

  return {
    createAssessmentGroup,
    createAssessmentGroupState: { error: error as Error, loading, reset },
  };
};

export default useCreateAssessmentGroup;
