import classNames from "classnames";
import React, { PropsWithChildren, useContext } from "react";
import ReactDOM from "react-dom";
import FocusLock from "react-focus-lock";
import { ClassNameProp } from "../utils";
import { useEscape } from "./hooks";
import ModalContext from "./ModalContext";

type ModalProp = {
  isOpen: boolean;
  portal?: boolean;
  onClose?: () => void;
};

const Modal: React.FC<PropsWithChildren<ModalProp>> = ({
  portal,
  children,
  isOpen,
  onClose,
  ...props
}) => {
  useEscape(onClose);
  const modal = (
    <ModalContext.Provider value={{ onClose }}>
      <FocusLock disabled={!isOpen}>
        <div
          className={classNames("modal", {
            "is-active": isOpen,
          })}
          aria-modal="true"
          role="dialog"
          hidden={!isOpen}
          {...props}
        >
          <div className="modal-background" onClick={onClose} />
          <div className="modal-card">{children}</div>
        </div>
      </FocusLock>
    </ModalContext.Provider>
  );

  return portal ? ReactDOM.createPortal(modal, window.document.body) : modal;
};

const Body: React.FC<PropsWithChildren & ClassNameProp> = ({
  children,
  className,
}) => (
  <section className={classNames("modal-card-body m-0", className)}>
    {children}
  </section>
);

const Header: React.FC<PropsWithChildren> = ({ children }) => {
  const { onClose } = useContext(ModalContext);

  return (
    <header className="modal-card-head has-background-primary">
      <p className="modal-card-title has-text-white">{children}</p>
      {onClose && (
        <button className="delete" aria-label="close" onClick={onClose} />
      )}
    </header>
  );
};

const Footer: React.FC<PropsWithChildren> = ({ children }) => (
  <footer className="modal-card-foot is-justify-content-end">{children}</footer>
);

export default Object.assign(Modal, {
  Header,
  Body,
  Footer,
});

export * from "./hooks";
