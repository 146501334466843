import React from "react";
import { HelpContent } from "../../components/Help";
import Resource from "../../components/Layouts/Resource";

const HelpScreen: React.FC = () => {
  return (
    <Resource>
      <Resource.Main>
        <Resource.Header>
          <h1 className="is-truncated">Help</h1>
        </Resource.Header>

        <Resource.Body>
          <HelpContent />
        </Resource.Body>
      </Resource.Main>
      <Resource.Navigation>
        <div className="is-flex is-flex-grow-1 has-scroll-y"></div>
      </Resource.Navigation>
    </Resource>
  );
};

export default HelpScreen;
