import React from "react";

import userInitials from "./userInitials";
import styles from "./UserIcon.module.scss";
import generateColor from "./generateColor";

type Props = {
  name: string;
  title?: string;
};

const UserIcon: React.FC<Props> = ({ name, title = name }) => (
  <span
    title={title}
    className={styles.userIcon}
    style={{ backgroundColor: generateColor(name) }}
  >
    {userInitials(name)}
  </span>
);
export default UserIcon;
