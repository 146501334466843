import React from "react";
import { useFormValidation, FormValidationProps } from "../../hooks";
import { Form } from "../../library";
import { NewAssessmentGroup } from "../../types";
import FormValidationError from "../FormValidationError";

type Props = FormValidationProps<NewAssessmentGroup>;

export const useNewAssessmentGroupDetailsForm = () =>
  useFormValidation<NewAssessmentGroup>();

const NewAssessmentGroupDetailsForm: React.FC<Props> = ({
  errors,
  register,
}) => {
  return (
    <Form>
      <Form.Field>
        <Form.Label>Patient name</Form.Label>
        <Form.InputUncontrolled
          {...register("patient", {
            required: true,
            maxLength: 150,
          })}
        />
        <FormValidationError field="Patient name" error={errors.patient} />
      </Form.Field>

      <Form.Field>
        <Form.Label>Assessment description</Form.Label>
        <Form.InputUncontrolled
          {...register("description", {
            required: true,
            maxLength: 150,
          })}
        />
        <FormValidationError
          field="Assessment description"
          error={errors.description}
        />
      </Form.Field>
    </Form>
  );
};

export default NewAssessmentGroupDetailsForm;
