import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import React from "react";
import assessmentDetails from "../../data/assessmentDetails";
import { Form } from "../../library";
import { AssessmentCriteria } from "../../types";

type Props = {
  criterion: AssessmentCriteria | null;
  setDetails: (details: string) => void;
};

const Details: React.FC<Props> = ({ criterion, setDetails }) => {
  const options = criterion
    ? assessmentDetails
        .filter((o) => !o.appliesTo || o.appliesTo.includes(criterion))
        .map((o) => o.value)
    : null;

  return options ? (
    <Form.Field>
      <Form.Label>Enter any additional criteria for this assessment</Form.Label>
      <Form.Control>
        <Combobox
          aria-label="Assessment details"
          openOnFocus
          onSelect={setDetails}
        >
          <ComboboxInput
            placeholder="Example: in existing equipment, box sitting..."
            className="input"
            onChange={(e) => {
              setDetails(e.target.value);
            }}
          />
          <ComboboxPopover style={{ zIndex: 500 }}>
            <ComboboxList className="dropdown-content">
              {options.map((result: string, index: number) => (
                <ComboboxOption
                  className="dropdown-item is-hoverable is-clickable"
                  key={index}
                  value={result}
                />
              ))}
            </ComboboxList>
          </ComboboxPopover>
        </Combobox>
      </Form.Control>
    </Form.Field>
  ) : null;
};

export default Details;
