import React from "react";
import { Button } from "../../library";

import styles from "./AssessmentControls.module.scss";

type NextProp = {
  onNext?: () => void;
};

type PrevProp = {
  onPrev?: () => void;
};

const Prev: React.FC<PrevProp> = ({ onPrev }) => (
  <span className={`is-flex ${styles.assessmentButtonContainer}`}>
    {onPrev ? (
      <Button title="Back to previous area" onClick={onPrev}>
        <Button.Icon icon="arrow-left" /> <span>Back</span>
      </Button>
    ) : (
      <span></span>
    )}
  </span>
);

const Next: React.FC<NextProp> = ({ onNext }) => (
  <span
    className={`is-flex is-justify-content-right ${styles.assessmentButtonContainer}`}
  >
    {onNext ? (
      <Button title="Assess next area" onClick={onNext}>
        <span>Next</span> <Button.Icon icon="arrow-right" />
      </Button>
    ) : (
      <span></span>
    )}
  </span>
);

export default Object.assign(
  {},
  {
    Next,
    Prev,
  }
);
