import { assessments } from "../../data";
import { getFilteredAssessmentAreas } from "../../data/assessment";

import { AssessmentResult, Assessment, Recommendation } from "../../types";

const calculateScore = (criteria: { data?: { value: number } }[]) =>
  criteria.map((c) => c.data?.value || 0).reduce((a, b) => a + b, 0);

// Filter the assessments that apply to the current assessment criterion
// and merge in the current assessment data
export const mergeAssessmentData = (
  selectedAssessment: Assessment
): AssessmentResult[] => {
  const findAssessmentData = (main: string, sub: string) =>
    selectedAssessment.assessmentScores?.find(
      (d) => d.main === main && d.sub === sub
    )?.data;

  return getFilteredAssessmentAreas(selectedAssessment).map((main) => {
    const criteria = main.criteria.map((sub) => ({
      data: findAssessmentData(main.name, sub.name),
      ...sub,
    }));

    return {
      name: main.name,
      score: calculateScore(criteria),
      criteria,
    };
  });
};

const defaultRecommendation = {
  problem: "",
  recommendation: "",
};

// Merge recommendation data into the assessment areas
export const mergeRecommendationDataForAssessment = (
  selectedAssessment: Assessment
) => {
  const findRecommendationData = (area: string) =>
    selectedAssessment.recommendations?.find(
      (data: Recommendation) => data.area === area
    );

  return getFilteredAssessmentAreas(selectedAssessment)
    .map(({ name }) => name)
    .map((area) => ({
      ...defaultRecommendation,
      ...findRecommendationData(area),
      area,
    }));
};

// Merge recommendation data into the assessment areas
export const mergeRecommendationData = (recommendations: Recommendation[]) => {
  const findRecommendationData = (area: string) =>
    recommendations?.find((data: Recommendation) => data.area === area);

  return assessments
    .map(({ name }) => name)
    .filter((v, i, a) => a.indexOf(v) === i) // distinct as some areas have duplicate definitions for different assessments
    .map((area) => ({
      ...defaultRecommendation,
      ...findRecommendationData(area),
      area,
    }));
};

export const getReassessmentFieldsToRetain = (assessment: Assessment) => ({
  patient: assessment.patient,
  recommendations: assessment.recommendations,
  equipmentPrescription: assessment.equipmentPrescription,
});
