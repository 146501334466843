import { GraphQLResult } from "@aws-amplify/api-graphql";

import { API, graphqlOperation } from "aws-amplify";

export function amplifyFetcher<
  TData,
  TVariables extends Record<string, unknown>
>(query: string, variables?: TVariables, options?: RequestInit["headers"]) {
  const operation = {
    ...graphqlOperation(query, variables),
  };

  return async (): Promise<TData> => {
    const result = (await API.graphql<TData>(
      operation
    )) as GraphQLResult<TData>;

    if (result.errors) {
      const { message } = result.errors[0];

      throw new Error(message);
    }

    return result.data!;
  };
}
