import { useModal } from "../../modal";

type Props = {
  onCancel?: () => void | Promise<void>;
  onConfirm?: () => void | Promise<void>;
};

export const useConfirmation = ({ onCancel, onConfirm }: Props = {}) => {
  const { isModalOpen, closeModal, openModal } = useModal();

  return {
    isOpen: isModalOpen,
    open: openModal,
    onConfirm: async () => {
      await onConfirm?.();
      closeModal();
    },
    onCancel: async () => {
      await onCancel?.();
      closeModal();
    },
  };
};

export default useConfirmation;
