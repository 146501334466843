import { QueryClient, MutationKey } from "@tanstack/react-query";
import { queryClient } from "../ReactQuery";

export const executeDefaultOnMutate = <T>(key: MutationKey, variables: T) =>
  queryClient.getMutationDefaults(key)?.onMutate?.(variables);

type VariablesWithId = { id: string } | undefined;

export const removeStaleMutationsFromCache = (
  queryClient: QueryClient,
  mutationKey: MutationKey,
  id: string
) => {
  const mutationCache = queryClient.getMutationCache();

  const cachedMutations = mutationCache.getAll();

  const staleMutations = cachedMutations.filter(
    (f, idx) =>
      f.state.isPaused &&
      JSON.stringify(f.options.mutationKey) === JSON.stringify(mutationKey) &&
      (f.state.variables as VariablesWithId)?.id === id &&
      idx !== cachedMutations.length - 1
  );

  staleMutations.map((mutation) => mutationCache.remove(mutation));
};
