import React, { useMemo } from "react";

import {
  useCreateAssessmentWithGroupId,
  useUpdateAssessmentGroup,
  useSearchParam,
} from "../../hooks";
import { Button, Icon, Slate } from "../../library";
import Loading from "../../components/Loading";
import { Inventory } from "../../components/Layouts";
import AssessmentGroupDetail from "../../components/AssessmentGroupDetail";
import {
  ExportAssessmentGroup,
  DeleteAssessmentGroup,
} from "../../components/AssessmentGroupToolbar";
import {
  AssessmentGroupDetailsForm,
  AssessmentGroupNotesForm,
} from "../../components/AssessmentGroupDetailsForm";
import { Icons } from "../../constants";
import SlideOverPanel from "../../components/SlideOverPanel";
import AssessmentRecommendations from "../../components/AssessmentRecommendations";
import { mergeRecommendationData } from "../../components/Assessment/utils";
import AssessmentEquipmentPrescription from "../../components/EquipmentPrescription";

type AssessmentsGroupDetailProps = {
  assessmentGroupId: string;
  onClose: () => void;
};

type UseSlideOverPanelFns<K extends string> = {
  [P in K]: {
    id: K;
    active: boolean;
    select: () => void;
  };
};

type UseSlideOverPanels<K extends string> = [
  UseSlideOverPanelFns<K>,
  () => void
];

function useSlideOverPanel<K extends string>(
  panelIds: readonly K[]
): UseSlideOverPanels<K> {
  const { value, set, clear } = useSearchParam("slide");

  const panels = useMemo(
    () =>
      Object.fromEntries(
        panelIds.map((id) => [
          id,
          {
            select: () => set(id),
            active: value === id,
            id,
          },
        ])
      ) as UseSlideOverPanelFns<K>,
    [value, panelIds, set]
  );

  return [panels, clear];
}

const AssessmentsScreenSidepanel: React.FC<AssessmentsGroupDetailProps> = ({
  assessmentGroupId,
  onClose,
}) => {
  const [panels, clearCurrentSlide] = useSlideOverPanel([
    "notes",
    "recommendations",
    "equipment",
    "edit",
  ]);

  const { createAssessment, createAssessmentState } =
    useCreateAssessmentWithGroupId(assessmentGroupId);

  const {
    assessmentGroup,
    assessmentGroupState,
    updateAssessmentGroup,
    updateAssessmentGroupState,
    save,
  } = useUpdateAssessmentGroup(assessmentGroupId, onClose);

  if (assessmentGroupState.loading) return <Loading />;

  if (assessmentGroupState.error)
    return (
      <Slate.Error>
        An error occurred when loading the assessment details
      </Slate.Error>
    );

  if (updateAssessmentGroupState.error)
    return (
      <Slate.Error retry={save}>
        An error occurred when saving the assessment details
      </Slate.Error>
    );

  if (assessmentGroup)
    return (
      <SlideOverPanel>
        <SlideOverPanel.Panel active>
          <Inventory.SidePanel.Header>
            <h2 className="is-inline-flex is-align-items-center mr-auto">
              {assessmentGroup.description}
            </h2>
            <Inventory.SidePanel.Close onClose={onClose} />
          </Inventory.SidePanel.Header>
          <Inventory.SidePanel.Body className="p-2">
            <Button
              className="is-ghost is-pulled-right"
              onClick={panels.edit.select}
            >
              <Button.Icon icon={Icons.edit} />
            </Button>
            <AssessmentGroupDetail
              assessmentGroup={assessmentGroup}
              createAssessmentState={createAssessmentState}
              createAssessment={createAssessment}
            />

            <h3 className="pt-4 pb-2">Assessment Outcomes</h3>
            <Inventory.SidePanel.Item onClick={panels.notes.select}>
              <Icon className="mr-3" icon={Icons.edit} />
              <h4>Notes</h4>
            </Inventory.SidePanel.Item>
            <Inventory.SidePanel.Item onClick={panels.recommendations.select}>
              <Icon className="mr-3" icon={Icons.recommendations} />
              <h4>Recommendations</h4>
            </Inventory.SidePanel.Item>
            <Inventory.SidePanel.Item onClick={panels.equipment.select}>
              <Icon className="mr-3" icon={Icons.equipmentPrescription} />
              <h4>Equipment Prescription</h4>
            </Inventory.SidePanel.Item>
          </Inventory.SidePanel.Body>
          <Inventory.SidePanel.Footer>
            <DeleteAssessmentGroup id={assessmentGroup.id} />

            <div className="mx-1 has-border-left"></div>

            <ExportAssessmentGroup assessmentGroup={assessmentGroup} />
          </Inventory.SidePanel.Footer>
        </SlideOverPanel.Panel>
        <SlideOverPanel.Panel active={panels.notes.active}>
          <Inventory.SidePanel.Header>
            <h2 className="is-inline-flex is-align-items-center mr-auto">
              Notes
            </h2>
            <Button
              title="Save and close"
              className="is-ghost"
              onClick={clearCurrentSlide}
            >
              <Button.Icon icon={Icons.done} />
            </Button>
          </Inventory.SidePanel.Header>
          <Inventory.SidePanel.Body className="p-2">
            <AssessmentGroupNotesForm
              notes={assessmentGroup.notes}
              setNotes={updateAssessmentGroup.setNotes}
            />
          </Inventory.SidePanel.Body>
        </SlideOverPanel.Panel>
        <SlideOverPanel.Panel active={panels.edit.active}>
          <Inventory.SidePanel.Header>
            <h2 className="is-inline-flex is-align-items-center mr-auto">
              Edit assessment details
            </h2>
            <Button
              title="Save and close"
              className="is-ghost"
              onClick={clearCurrentSlide}
            >
              <Button.Icon icon={Icons.done} />
            </Button>
          </Inventory.SidePanel.Header>
          <Inventory.SidePanel.Body className="p-2">
            <AssessmentGroupDetailsForm
              assessmentGroup={assessmentGroup}
              updateAssessmentGroup={updateAssessmentGroup.setDetails}
            />
          </Inventory.SidePanel.Body>
        </SlideOverPanel.Panel>
        <SlideOverPanel.Panel active={panels.recommendations.active}>
          <Inventory.SidePanel.Header>
            <h2 className="is-inline-flex is-align-items-center mr-auto">
              Assessment Recommendations
            </h2>
            <Button
              title="Save and close"
              className="is-ghost"
              onClick={clearCurrentSlide}
            >
              <Button.Icon icon={Icons.done} />
            </Button>
          </Inventory.SidePanel.Header>
          <Inventory.SidePanel.Body className="p-2">
            <AssessmentRecommendations
              recommendations={mergeRecommendationData(
                assessmentGroup.recommendations
              )}
              updateRecommendations={
                updateAssessmentGroup.setRecommendationData
              }
            />
          </Inventory.SidePanel.Body>
        </SlideOverPanel.Panel>
        <SlideOverPanel.Panel active={panels.equipment.active}>
          <Inventory.SidePanel.Header>
            <h2 className="is-inline-flex is-align-items-center mr-auto">
              Equipment Prescription
            </h2>
            <Button
              title="Save and close"
              className="is-ghost"
              onClick={clearCurrentSlide}
            >
              <Button.Icon icon={Icons.done} />
            </Button>
          </Inventory.SidePanel.Header>
          <Inventory.SidePanel.Body className="p-2">
            <AssessmentEquipmentPrescription
              equipmentPrescription={assessmentGroup.equipmentPrescription}
              setEquipmentPrescription={
                updateAssessmentGroup.setEquipmentPrescriptionData
              }
            />
          </Inventory.SidePanel.Body>
        </SlideOverPanel.Panel>
      </SlideOverPanel>
    );

  if (assessmentGroupState.paused) return <Slate.Offline />;

  return null; // hydrating from local storage
};

export default AssessmentsScreenSidepanel;
