import React, { PropsWithChildren } from "react";
import classNames from "classnames";

import { htmlElementComponent } from "../../library";
import styles from "./SliderOverPanel.module.scss";

const SlideOver = htmlElementComponent({
  classes: "is-clipped is-relative is-fullheight",
});

type PanelProps = {
  active: boolean;
};

const Panel: React.FC<PropsWithChildren<PanelProps>> = ({
  children,
  active,
}) => (
  <div
    aria-hidden={!active}
    tabIndex={active ? undefined : -1}
    className={classNames(
      styles.panel,
      "is-flex is-flex-direction-column is-fullheight has-background-white is-fullwidth has-transition",
      {
        [styles.activePanel]: active,
      }
    )}
  >
    {children}
  </div>
);

export default Object.assign(SlideOver, { Panel });
