import classNames from "classnames";
import React, { useEffect } from "react";
import { useDelayedLoader } from "../../hooks";
import { Loader } from "../../library";

import styles from "./AssessmentImage.module.css";

type ImageProps = {
  src: string;
};

const AssessmentImage: React.FC<ImageProps> = ({ src }) => {
  const { init, onLoad, showLoader, isLoaded } = useDelayedLoader();

  useEffect(init, [init, src]);

  return (
    <div className={styles.AssessmentImageContainer}>
      {showLoader ? <Loader className="mx-auto" size="small" /> : null}
      <img
        className={classNames(styles.AssessmentImage, {
          [styles.AssessmentImageLoading]: !isLoaded,
        })}
        alt="Assessment diagram"
        src={src}
        key={src}
        onLoad={onLoad}
      />
    </div>
  );
};

export default AssessmentImage;
