import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

import useIsLoading from "./useIsLoading";

type UserAttributes = {
  identifier: string; // name or email if name isn't set
  name: string;
  email: string;
};

type UpdatableUserAttributes = Partial<Pick<UserAttributes, "name">>;

type UseCurrentUser = {
  user?: UserAttributes;
  isUpdating: boolean;
  updateUser: (attributes: UpdatableUserAttributes) => void;
};

const useCurrentUser = (): UseCurrentUser => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { execute, isLoading } = useIsLoading(
    async (attributes: Partial<UpdatableUserAttributes>) => {
      await Auth.updateUserAttributes(user, attributes);
    }
  );

  return {
    user: user?.attributes
      ? {
          email: user.attributes.email,
          name: user.attributes.name || "",
          identifier: user.attributes.name || user.attributes.email,
        }
      : undefined,
    isUpdating: isLoading,
    updateUser: execute,
  };
};

export default useCurrentUser;
