import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import Icon from "../icon";
import { ClassNameProp } from "../utils";

export type FormErrorProps = ClassNameProp;

const Error: React.FC<PropsWithChildren<FormErrorProps>> = ({
  children,
  className,
}) => {
  return (
    <p className={classNames("help is-danger", className)}>
      <Icon icon="triangle-exclamation" />
      {children}
    </p>
  );
};

export default Error;
