import { useCallback } from "react";

import {
  Recommendation,
  UpdateAssessmentGroupFns,
  AssessmentGroup,
  AssessmentGroupDetails,
} from "../types";

type SetAssessmentGroupFn = (
  fn: (assessmentGroup: AssessmentGroup) => AssessmentGroup
) => void;

const useUpdateAssessmentGroupHelpers = (
  setAssessmentGroup: SetAssessmentGroupFn
): UpdateAssessmentGroupFns => {
  const setPartial = useCallback(
    (values: Partial<AssessmentGroup>) =>
      setAssessmentGroup((currentState) => ({
        ...currentState,
        ...values,
      })),
    [setAssessmentGroup]
  );

  const setDetails = useCallback(
    (details: AssessmentGroupDetails) => setPartial(details),
    [setPartial]
  );

  const setNotes = useCallback(
    (notes: string) => setPartial({ notes }),
    [setPartial]
  );

  const setEquipmentPrescriptionData = useCallback(
    (equipmentPrescription: string) => setPartial({ equipmentPrescription }),
    [setPartial]
  );

  const setRecommendationData = useCallback(
    (recommendations: Recommendation[]) => setPartial({ recommendations }),
    [setPartial]
  );

  return {
    setDetails,
    setNotes,
    setEquipmentPrescriptionData,
    setRecommendationData,
  };
};

export default useUpdateAssessmentGroupHelpers;
