import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ulid } from "ulid";
import { useMutation } from "@tanstack/react-query";
import {
  Assessment,
  CreateNewAssessmentFn,
  defaultAssessment,
  RemoteOperationState,
} from "../../types";
import {
  CreateAssessmentMutation,
  useCreateAssessmentMutation,
} from "../../graphql/generated";
import { executeDefaultOnMutate } from "../../graphql/utils";

type LocalCreateNewAssessmentFn = (assessment: Assessment) => void;

type UseCreateAssessment = {
  createAssessment: LocalCreateNewAssessmentFn;
  createAssessmentState: RemoteOperationState;
};

const useCreateAssessment = (): UseCreateAssessment => {
  const navigate = useNavigate();

  const {
    mutate,
    error,
    isLoading: loading,
    reset,
  } = useMutation<CreateAssessmentMutation, unknown, Assessment>(
    useCreateAssessmentMutation.getKey(),
    {
      onMutate: async (assessment: Assessment) => {
        await executeDefaultOnMutate(
          useCreateAssessmentMutation.getKey(),
          assessment
        );

        navigate(`/assessment/${assessment.id}`);
      },
    }
  );

  return {
    createAssessment: mutate,
    createAssessmentState: { error: error as Error, loading, reset },
  };
};

export const useCreateAssessmentWithGroupId = (assessmentGroupId: string) => {
  const result = useCreateAssessment();

  const createAssessment: CreateNewAssessmentFn = useCallback(
    (assessmentCriterion, assessment) =>
      result.createAssessment(
        defaultAssessment(
          `${assessmentGroupId}_${ulid()}`,
          assessmentGroupId,
          assessmentCriterion,
          assessment
        )
      ),
    [assessmentGroupId, result]
  );

  return {
    ...result,
    createAssessment,
  };
};

export default useCreateAssessment;
