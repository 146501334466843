import React, { PropsWithChildren } from "react";

import { HelpModal } from "../Help";
import { EmailLinkDark } from "../EmailLink";

const Information: React.FC = () => (
  <div>
    <div>
      <HelpModal />
    </div>
    <div>
      <EmailLinkDark className="mx-4" />
    </div>
  </div>
);

const Container: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="is-flex is-flex-direction-column has-text-white has-background-feature is-fullheight">
      {children}
    </div>
  );
};

const Content: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="is-flex-grow-1 is-flex is-flex-direction-column is-justify-content-space-between">
      <span></span>
      <div className="is-flex is-flex-direction-column is-align-items-center">
        {children}
      </div>
      <Information />
    </div>
  );
};

const Header: React.FC<PropsWithChildren> = ({ children }) => (
  <h1 className="has-text-weight-semibold mb-3">{children}</h1>
);

const Dark = {
  Header,
  Container,
  Content,
};

export default Dark;
