import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import features from "../../features";
import { useCurrentUser } from "../../hooks";
import { Nav } from "../../library";
import useClickOutside from "../../library/hooks/useClickOutside";
import UserIcon from "../UserIcon";

const UserMenu: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(() => setIsOpen(false), ref);

  const { user } = useCurrentUser();

  if (!user)
    return (
      <Nav.Item>
        <Link className="button is-primary" to="/sign-in" title="Sign in">
          Sign in
        </Link>
      </Nav.Item>
    );

  const icon = (
    <UserIcon title={`Logged in as ${user.email}`} name={user.identifier} />
  );

  return (
    <Nav.Item
      ref={ref}
      className={classNames({
        "is-active": isOpen,
      })}
    >
      <Nav.Item
        onClick={() => setIsOpen((o) => !o)}
        className="navbar-link is-arrowless"
      >
        {icon}
      </Nav.Item>
      <Nav.Dropdown className="is-right is-boxed mr-1">
        <Nav.Item>
          {icon}
          <p className="pl-3">{user.email}</p>
        </Nav.Item>
        <hr className="navbar-divider" />
        <Nav.Item>
          <Link to="/account">Account settings</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/help">Help</Link>
        </Nav.Item>
        <hr className="navbar-divider" />
        <Nav.Item>
          <Link to="/sign-out">Sign out</Link>
        </Nav.Item>
      </Nav.Dropdown>
    </Nav.Item>
  );
};

export default UserMenu;

export const FlaggedUserMenu: React.FC = () =>
  features.accounts ? <UserMenu /> : null;
