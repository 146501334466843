import ReactPDF, { PDFDownloadLink } from "@react-pdf/renderer";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Button, Loader } from "../../library";
import { ClassNameProp } from "../../library/utils";
import { Icons } from "../../constants";

type ExportPdfProps = {
  filename: string;
  children: React.ReactElement<ReactPDF.DocumentProps, string>;
  onExport?: () => void;
} & ClassNameProp;

const sanitizeFileName = (s: string) =>
  s.replace(/[^a-z0-9]/gi, "_").toLowerCase();

const ExportPdf: React.FC<ExportPdfProps> = ({
  children,
  onExport,
  className = "button m-3",
  filename,
}) => {
  const [hasExported, setHasExported] = useState(false);

  useEffect(() => {
    let timer1: ReturnType<typeof setTimeout> | null = null;

    if (hasExported) {
      if (timer1) clearTimeout(timer1);
      timer1 = setTimeout(() => setHasExported(false), 2000);
    }

    return () => {
      if (timer1) clearTimeout(timer1);
    };
  }, [hasExported]);

  return (
    <>
      <PDFDownloadLink
        onClick={() => {
          onExport?.();
          setHasExported(true);
        }}
        fileName={sanitizeFileName(filename)}
        document={children}
        className={classNames({
          "is-hidden": hasExported,
        })}
      >
        {({ loading }) =>
          loading ? (
            <span className="is-flex is-flex-direction-column is-align-items-center">
              <Loader size="small" className="mb-2" /> Exporting PDF
            </span>
          ) : (
            <span className={className}>
              <Button.Icon
                title="Click to download your PDF"
                icon={Icons.export}
              />
              <span>Click to download your PDF</span>
            </span>
          )
        }
      </PDFDownloadLink>
      {hasExported && (
        <span title="Your PDF is downloading..." className={className}>
          <span className="is-flex is-align-items-center">
            <Button.Icon icon={Icons.export} />
            <span>Your PDF is downloading...</span>
          </span>
        </span>
      )}
    </>
  );
};

export default ExportPdf;
