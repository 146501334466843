import React from "react";
import { Icons } from "../../constants";
import { useConfirmation, Confirm, Button } from "../../library";
import { AssessmentGroup } from "../../types";
import { formatDateTimeForFilename } from "../../utils/date";
import { AssessmentGroupPdf } from "../AssessmentSummaryPdf";
import ExportPdf from "../ExportPdf";

const formatFilename = (assessmentGroup: AssessmentGroup) =>
  `caba_${assessmentGroup.patient}_${
    assessmentGroup.description
  }_${formatDateTimeForFilename(assessmentGroup.assessmentDate)}.pdf`;

type ExportAssessmentGroupProps = {
  assessmentGroup: AssessmentGroup;
};
const ExportAssessmentGroup: React.FC<ExportAssessmentGroupProps> = ({
  assessmentGroup,
}) => {
  const { open, ...props } = useConfirmation();

  return (
    <>
      <Confirm {...props} onConfirm={undefined} title="Export assessment">
        <ExportPdf
          className="button"
          filename={formatFilename(assessmentGroup)}
        >
          <AssessmentGroupPdf assessmentGroup={assessmentGroup} />
        </ExportPdf>
      </Confirm>
      <Button title="Export assessment" onClick={open}>
        <Button.Icon icon={Icons.export} />
        <span>Export PDF</span>
      </Button>
    </>
  );
};

export default ExportAssessmentGroup;
