import React from "react";
import { Dark } from "../../components/Layouts";

const SignedOutScreen: React.FC = () => {
  return (
    <Dark.Container>
      <Dark.Content>
        <Dark.Header>You have been signed out</Dark.Header>

        <a className="button is-primary" href="/sign-in">
          Log back in
        </a>
      </Dark.Content>
    </Dark.Container>
  );
};

export default SignedOutScreen;
