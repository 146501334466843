import classNames from "classnames";
import React from "react";
import { ClassNameProp } from "../utils";

type RadioOption<T> = {
  value: T;
  label: string;
};

type RadioProps<T> = {
  onChange: (value: T) => void;
  value?: T;
  options: RadioOption<T>[];
  placeholder?: string;
  name?: string;
  labelClassName?: string;
} & ClassNameProp;

const Radio = <T extends string>({
  onChange,
  value,
  options,
  name = `name-${Math.random()}`,
  className,
}: RadioProps<T>) => (
  <>
    {options.map(({ value: v, label }) => (
      <label className={classNames("radio", className)} key={v}>
        <input
          name={name}
          type="radio"
          checked={v === value}
          onChange={(): void => onChange(v)}
        />
        {label}
      </label>
    ))}
  </>
);

export default Radio;
