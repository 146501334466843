import React from "react";

import { Banner, Form, Menu, Icon } from "../../library";
import { NavLink } from "react-router-dom";
import { useCurrentUser, useFormValidation } from "../../hooks";
import Resource from "../../components/Layouts/Resource";
import FormValidationError from "../../components/FormValidationError";
import { Icons } from "../../constants";
import Loading from "../../components/Loading";

const AccountScreen: React.FC = () => {
  const retry = undefined;

  const { user, updateUser } = useCurrentUser();

  return (
    <Resource>
      <Resource.Main>
        <Resource.Header>
          <h1 className="is-truncated">Account details</h1>
        </Resource.Header>
        {retry && (
          <Banner retry={retry}>There was an error saving your details</Banner>
        )}
        <Resource.Body>
          {user ? (
            <AccountDetailsForm user={user} updateUser={updateUser} />
          ) : (
            <Loading />
          )}
        </Resource.Body>
      </Resource.Main>
      <Resource.Navigation>
        <div className="is-flex is-flex-grow-1 has-scroll-y">
          <Menu>
            <Menu.List>
              <Menu.Item>
                <NavLink end to="/">
                  <Icon icon={Icons.home} />
                  Home
                </NavLink>
              </Menu.Item>
            </Menu.List>
          </Menu>
        </div>
      </Resource.Navigation>
    </Resource>
  );
};

export type AccountDetailsFields = {
  name: string;
};

type Props = {
  user: AccountDetailsFields;
  updateUser: (data: AccountDetailsFields) => void;
};

const AccountDetailsForm: React.FC<Props> = ({ user, updateUser }) => {
  const { register, errors, handleBlur } =
    useFormValidation<AccountDetailsFields>({
      values: user,
    });

  return (
    <Form onBlur={handleBlur(updateUser)}>
      <Form.Field>
        <Form.Label>Name</Form.Label>
        <Form.InputUncontrolled
          {...register("name", {
            required: true,
            maxLength: 150,
          })}
        />
        <FormValidationError field="Name" error={errors.name} />
      </Form.Field>
    </Form>
  );
};

export default AccountScreen;
