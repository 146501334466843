import React, { PropsWithChildren } from "react";
import Button from "../button";

type Props = {
  retry?: () => void;
};

const Banner: React.FC<PropsWithChildren<Props>> = ({ children, retry }) => (
  <div className="is-flex is-align-items-center is-justify-content-center has-background-danger-light has-text-danger p-3">
    <p className="px-3">{children}</p>
    {retry && (
      <Button className="is-small is-outlined" variant="danger" onClick={retry}>
        Click to retry
      </Button>
    )}
  </div>
);

export default Banner;
