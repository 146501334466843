import React, { PropsWithChildren } from "react";

import { ClassNameProp, HtmlComponent } from "../../library/utils";
import classNames from "classnames";
import { Button, htmlElementComponent, Icon } from "../../library";
import styles from "./Inventory.module.scss";

type SidePanelProps = {
  open: boolean;
} & ClassNameProp;

const SidePanel: React.FC<PropsWithChildren<SidePanelProps>> = ({
  children,
  className,
  open,
}) => {
  return (
    <aside
      className={classNames(
        "is-flex is-flex-direction-column p-1 has-border-left transition",
        styles.sidePanel,
        className,
        {
          [styles.sidePanelActive]: open,
        }
      )}
    >
      {children}
    </aside>
  );
};

type SidePanelCloseProps = {
  onClose: () => void;
};

const SidePanelClose: React.FC<SidePanelCloseProps> = ({ onClose }) => {
  return (
    <Button title="Close" className="is-ghost" onClick={onClose}>
      <Button.Icon icon="close" />
    </Button>
  );
};

const SidePanelHeader = htmlElementComponent({
  classes: "is-flex p-2 has-border-bottom",
});

const SidePanelBody = htmlElementComponent({
  classes: "has-scroll-y",
});

const SidePanelFooter = htmlElementComponent({
  classes: "is-flex mt-auto p-3 has-border-top",
});

const SidePanelItem: React.FC<HtmlComponent> = ({
  onClick,
  children,
  ...props
}) => {
  return (
    <div
      className={classNames(
        "py-3 px-1 is-align-items-center is-flex has-border-top last-has-border-bottom",
        { "is-clickable": !!onClick }
      )}
      onClick={onClick}
      {...props}
    >
      {children}
      {onClick && <Icon className="is-ghost ml-auto" icon="chevron-right" />}
    </div>
  );
};

const Toolbar = htmlElementComponent({
  classes: "py-2 is-flex is-justify-content-space-between",
});
const Content = htmlElementComponent({
  classes: "p-1 is-flex is-flex-direction-column is-flex-grow-1",
});
const Inventory = htmlElementComponent({
  classes: "is-flex is-flex-grow-1",
});
const Items = htmlElementComponent({
  classes:
    "pt-1 is-flex is-flex-grow-1 is-flex-direction-column has-scroll-y has-border-top",
});

export default Object.assign(Inventory, {
  Content,
  Items,
  Toolbar,
  SidePanel: Object.assign(SidePanel, {
    Header: SidePanelHeader,
    Close: SidePanelClose,
    Body: SidePanelBody,
    Footer: SidePanelFooter,
    Item: SidePanelItem,
  }),
});
