import { useCallback, useState } from "react";
import { AssessmentCriteria } from "../../types";

const useAssessmentCriteriaSelection = (
  defaultCriteria: AssessmentCriteria | null = null
) => {
  const [criterion, setCriterion] = useState(defaultCriteria);

  const [details, setDetails] = useState<string>("");

  const reset = useCallback(() => {
    setDetails("");
    setCriterion(null);
  }, []);

  return {
    reset,
    criterion,
    setCriterion,
    details,
    setDetails,
  };
};

export default useAssessmentCriteriaSelection;
