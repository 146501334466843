import React, { useCallback, useMemo } from "react";
import { useSwipeable } from "react-swipeable";
import { PDFViewer } from "@react-pdf/renderer";

import { Banner, Form, Detail } from "../../library";
import AssessmentControls from "../AssessmentControls";
import AssessmentImage from "../AssessmentImage";
import AssesmentMenu from "../AssessmentMenu";
import AssessmentOptions from "../AssessmentOptions";
import CompleteAssessment from "../CompleteAssessmentModal";
import Page from "../Page";
import PatientDetails from "../PatientDetails";
import { AssessmentCriterion } from "../AssessmentCriteriaSelection";
import AssessmentSummary from "../AssessmentSummary";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";
import Sketch from "../Sketch";
import assessmentSketches from "../../data/assessmentSketch";
import { useNextPrev } from "../../hooks";
import AssessmentRecommendations from "../AssessmentRecommendations";
import { AssessmentPdf } from "../AssessmentSummaryPdf";
import features from "../../features";
import AssessmentEquipmentPrescription from "../EquipmentPrescription";

import {
  Assessment,
  CreateNewAssessmentFn,
  UpdateAssessmentFns,
} from "../../types";

import {
  getReassessmentFieldsToRetain,
  mergeAssessmentData,
  mergeRecommendationDataForAssessment,
} from "./utils";
import Resource from "../Layouts/Resource";
import { Icons } from "../../constants";
import BackToAssessmentsLink from "../BackToAssessmentsLink";
import { FriendlyTime } from "../../utils/date";

enum Pages {
  PatientDetails = "PatientDetails",
  Assessment = "Assessment",
  AssessmentSummary = "AssessmentSummary",
  AssessmentDetails = "AssessmentDetails",
  AssessmentSketch = "AssessmentSketch",
  PDFViewer = "PDFViewer",
}

type ChildPage = {
  id: string;
  label: string;
  content: React.ReactElement;
};

type ParentPage = {
  id: string;
  label: string;
  icon?: string;
  disabled?: boolean;
  content?: React.ReactElement;
  children?: ChildPage[];
};

const makeId = (...parts: string[]) => parts.join(":");

const flattenPages = (pages: ParentPage[]) =>
  pages.flatMap(
    (p) =>
      p.children?.map((c) => ({
        ...c,
        id: makeId(p.id, c.id),
        label: `${p.label} » ${c.label}`,
      })) ?? [p]
  );

type AssessmentProps = {
  retry?: () => void;
  createAssessment: CreateNewAssessmentFn;
  clearSelectedAssessment: () => void;
  selectedAssessment: Assessment;
  updateSelectedAssessment: UpdateAssessmentFns;
  hasRealAssessmentGroup?: boolean;
};

const AssessmentComponent: React.FC<AssessmentProps> = ({
  retry,
  createAssessment,
  clearSelectedAssessment,
  selectedAssessment,
  updateSelectedAssessment,
  hasRealAssessmentGroup = false,
}) => {
  const [assessmentResults, recommendations] = useMemo(
    () => [
      mergeAssessmentData(selectedAssessment),
      mergeRecommendationDataForAssessment(selectedAssessment),
    ],
    [selectedAssessment]
  );

  const sketches =
    assessmentSketches[selectedAssessment.assessmentCriterion.criterion];

  const sketchImages = sketches.map(
    (s) =>
      selectedAssessment.sketches?.find(({ id }) => id === s.id)?.image ?? s.src
  );

  const pages = useMemo(() => {
    const pages: ParentPage[] = [
      {
        id: Pages.PDFViewer,
        label: "PDF Viewer",
        disabled: !features.pdfviewer,
        content: (
          <PDFViewer height={800} showToolbar={false}>
            <AssessmentPdf
              equipmentPrescription={selectedAssessment.equipmentPrescription}
              recommendations={recommendations}
              assessmentImages={sketchImages}
              assessmentCriterion={selectedAssessment.assessmentCriterion}
              patientDetails={selectedAssessment.patient}
              assessmentResults={assessmentResults}
            />
          </PDFViewer>
        ),
      },
      {
        id: Pages.PatientDetails,
        label: "Patient details",
        icon: Icons.patient,
        disabled: hasRealAssessmentGroup,
        content: (
          <PatientDetails
            updatePatient={updateSelectedAssessment.setPatientData}
            patient={selectedAssessment.patient}
          />
        ),
      },
      ...assessmentResults.map((a) => ({
        id: a.name,
        label: a.name,
        children: a.criteria.map(({ name, image, options, data }) => ({
          id: name,
          label: name,
          content: (
            <>
              <span>{/*flex spacer to center image*/}</span>
              <AssessmentImage src={image} />
              <AssessmentOptions
                selectedOption={data || {}}
                onChange={(direction, value) =>
                  updateSelectedAssessment.setAssessmentData({
                    main: a.name,
                    sub: name,
                    data: { direction, value },
                  })
                }
                options={options}
              />
            </>
          ),
        })),
      })),
      {
        id: Pages.AssessmentSketch,
        icon: Icons.sketch,
        label: "Sketch",
        children: sketches.map((s, idx) => ({
          id: idx.toString(),
          label: s.id,
          content: (
            <Sketch
              key={s.id}
              saveData={
                selectedAssessment.sketches?.find(({ id }) => id === s.id)?.data
              }
              image={s.src}
              onChange={(data) =>
                updateSelectedAssessment.setSketchData({
                  id: s.id,
                  ...data,
                })
              }
            />
          ),
        })),
      },
      {
        id: Pages.AssessmentSummary,
        icon: Icons.summary,
        label: "Summary",
        content: (
          <AssessmentSummary
            assessmentCriterion={selectedAssessment.assessmentCriterion}
            patientDetails={selectedAssessment.patient}
            assessmentResults={assessmentResults}
          />
        ),
      },
      {
        id: Pages.AssessmentDetails,
        icon: Icons.recommendations,
        label: "Recommendations",
        disabled: hasRealAssessmentGroup,
        content: (
          <Form>
            <AssessmentRecommendations
              recommendations={recommendations}
              updateRecommendations={
                updateSelectedAssessment.setRecommendationData
              }
            />
            <AssessmentEquipmentPrescription
              equipmentPrescription={selectedAssessment.equipmentPrescription}
              setEquipmentPrescription={
                updateSelectedAssessment.setEquipmentPrescriptionData
              }
            />
          </Form>
        ),
      },
    ];
    return pages.filter((p) => !p.disabled);
  }, [
    hasRealAssessmentGroup,
    selectedAssessment.equipmentPrescription,
    selectedAssessment.assessmentCriterion,
    selectedAssessment.patient,
    selectedAssessment.sketches,
    recommendations,
    sketchImages,
    assessmentResults,
    updateSelectedAssessment,
    sketches,
  ]);

  const flattenedPages = flattenPages(pages);

  const pageIds = flattenedPages.map((p) => p.id);

  const {
    next,
    prev,
    reset: resetPage,
    current,
    setCurrent,
    isLast,
  } = useNextPrev(pageIds);

  const exitAssessment = useCallback(() => {
    clearSelectedAssessment();
    resetPage();
  }, [clearSelectedAssessment, resetPage]);

  const completeAnotherAssessment = useCallback(
    (assessmentCriterion: AssessmentCriterion) => {
      resetPage();
      createAssessment(
        assessmentCriterion,
        getReassessmentFieldsToRetain(selectedAssessment)
      );
    },
    [createAssessment, resetPage, selectedAssessment]
  );

  const completeAssessmentButton = hasRealAssessmentGroup ? null : (
    <CompleteAssessment
      recommendations={recommendations}
      equipmentPrescription={selectedAssessment.equipmentPrescription}
      assessmentCriterion={selectedAssessment.assessmentCriterion}
      patientDetails={selectedAssessment.patient}
      assessmentResults={assessmentResults}
      exitAssessment={exitAssessment}
      completeAnotherAssessment={completeAnotherAssessment}
      assessmentImages={sketchImages}
    />
  );

  const currentPage = flattenedPages.find((p) => p.id === current);

  const isSketchScreen = current.startsWith(Pages.AssessmentSketch);

  const swipeHandlers = useSwipeable({
    onSwipedRight: prev,
    onSwipedLeft: next,
    preventScrollOnSwipe: true,
    // don't enable on the sketch screens as we need to be able to swipe
    // really we should prevent the touch events bubbling out of the sketch elements, but this
    // is a simple enough work around
    trackTouch: !isSketchScreen,
  });

  return (
    <Resource>
      <Page.Main>
        <Resource.Header>
          <h1 className="is-truncated is-size-6">
            {formatAssessmentCriterion(selectedAssessment.assessmentCriterion)}
          </h1>
          <h2 className="is-truncated is-size-5">{currentPage?.label}</h2>
        </Resource.Header>
        {retry && (
          <Banner retry={retry}>
            There was an error saving the assessment
          </Banner>
        )}
        <Resource.Body {...swipeHandlers}>{currentPage?.content}</Resource.Body>
        <div className="px-4 pb-2 pt-6 is-flex is-justify-content-space-between">
          <AssessmentControls.Prev onPrev={prev} />
          {isLast ? (
            completeAssessmentButton
          ) : (
            <AssessmentControls.Next onNext={next} />
          )}
        </div>
        <Resource.Footer>
          <Detail
            className="is-size-7 is-align-self-center"
            label="Last saved:"
          >
            <FriendlyTime date={selectedAssessment.updatedAt} />
          </Detail>
          <BackToAssessmentsLink id={selectedAssessment.assessmentGroupId} />
        </Resource.Footer>
      </Page.Main>
      <Resource.Navigation>
        <div className="is-flex is-flex-grow-1 has-scroll-y">
          <AssesmentMenu
            onSelect={(...path) => setCurrent(makeId(...path))}
            currentItem={current.split(":")}
            items={pages}
          />
        </div>
        {completeAssessmentButton}
      </Resource.Navigation>
    </Resource>
  );
};

export default AssessmentComponent;
