import { AssessmentSection } from "./data";

import {
  AssessmentCriteria,
  AssessmentCriterion,
  AssessmentScoreValue,
  AssessmentScore,
  Patient,
  Recommendation,
  Sketch,
  Assessment as RemoteAssessment,
  AssessmentGroup as RemoteAssessmentGroup,
} from "./graphql/generated";

export type {
  AssessmentCriterion,
  Patient,
  Sketch,
  Recommendation,
  AssessmentScore,
};

export type Therapist = {
  name: string;
};

export type NewAssessmentGroup = Pick<
  AssessmentGroup,
  "patient" | "description"
>;

// Specify the optional fields to be required
// We will patch them with defaults if not set and use this type from there onwards
export type Assessment = RemoteAssessment & {
  assessmentScores: AssessmentScore[];
  equipmentPrescription: EquipmentPrescription;
  patient: Patient;
  recommendations: Recommendation[];
  sketches: Sketch[];
  updatedAt: string;
};

// Patch assessment group to refrence the patched Remote assessment
export type AssessmentGroup = Omit<RemoteAssessmentGroup, "assessments"> & {
  assessments: Assessment[];
  equipmentPrescription: EquipmentPrescription;
  notes: string;
  therapist: string;
  location: string;
  recommendations: Recommendation[];
  updatedAt: string;
};

export type EquipmentPrescription = string;

export type AssessmentGroupDetails = Pick<
  AssessmentGroup,
  "patient" | "description" | "therapist" | "location"
>;

type AssessmentCriteriaResult = AssessmentSection & {
  data?: AssessmentScoreValue;
};

export type AssessmentResult = {
  name: string;
  score: number;
  criteria: AssessmentCriteriaResult[];
};

export type SetEquipmentPrescriptionFn = (
  equipmentPrescription: EquipmentPrescription
) => void;

export type SetAssessmentDataFn = (data: AssessmentScore) => void;

export type SetAssessmentGroupDetailsFn = (
  details: AssessmentGroupDetails
) => void;

export type SetAssessmentGroupNotesFn = (notes: string) => void;

export type SetRecommendationFn = (recommendation: Recommendation[]) => void;

export type SetAssessmentSketchFn = (sketch: Sketch) => void;

export type SetAssessmentPatientFn = (patient: Partial<Patient>) => void;

export type CreateNewAssessmentFn = (
  assessmentCriterion: AssessmentCriterion,
  assessment?: Partial<Omit<Assessment, "criterion">>
) => void;

export type CreateNewAssessmentGroupFn = (
  assessmentGroup: NewAssessmentGroup,
  assessmentCriterion: AssessmentCriterion
) => void;

export type UpdateAssessmentFns = {
  setAssessmentData: SetAssessmentDataFn;
  setSketchData: SetAssessmentSketchFn;
  setPatientData: SetAssessmentPatientFn;
  setEquipmentPrescriptionData: SetEquipmentPrescriptionFn;
  setRecommendationData: SetRecommendationFn;
};

export type UpdateAssessmentGroupFns = {
  setEquipmentPrescriptionData: SetEquipmentPrescriptionFn;
  setRecommendationData: SetRecommendationFn;
  setDetails: SetAssessmentGroupDetailsFn;
  setNotes: SetAssessmentGroupNotesFn;
};

export type AllAssessmentData = {
  patientDetails: Patient;
  recommendations: Recommendation[];
  equipmentPrescription: EquipmentPrescription;
  assessmentResults: AssessmentResult[];
  assessmentCriterion: AssessmentCriterion;
  assessmentImages: string[];
};

export type RemoteOperationState = {
  paused?: boolean;
  error?: Error;
  loading: boolean;
  reset?: () => void;
};

export const defaultPatient = (patient?: Partial<Patient> | null) => ({
  name: patient?.name ?? "",
  therapist: patient?.therapist ?? "",
  location: patient?.location ?? "",
  date: patient?.date ?? new Date().toLocaleDateString("en-CA"),
  notes: patient?.notes ?? "",
});

export const defaultAssessment = (
  id: string,
  assessmentGroupId: string,
  assessmentCriterion: AssessmentCriterion,
  assessment: Partial<RemoteAssessment> = {}
) => ({
  ...assessment,
  assessmentScores: assessment.assessmentScores ?? [],
  sketches: assessment.sketches ?? [],
  patient: defaultPatient(assessment.patient),
  recommendations: assessment.recommendations ?? [],
  equipmentPrescription: assessment.equipmentPrescription ?? "",
  createdAt: assessment.createdAt ?? new Date().toISOString(),
  updatedAt:
    assessment.updatedAt ?? assessment.createdAt ?? new Date().toISOString(),
  assessmentCriterion,
  assessmentGroupId,
  id,
});

export const defaultAssessmentGroup = (
  id: string,
  patient: string,
  description: string,
  assessmentGroup: Partial<RemoteAssessmentGroup> = {}
) => ({
  ...assessmentGroup,
  id,
  description,
  patient,
  updatedAt:
    assessmentGroup.updatedAt ??
    assessmentGroup.createdAt ??
    new Date().toISOString(),
  createdAt: assessmentGroup.createdAt ?? new Date().toISOString(),
  assessmentDate: assessmentGroup.assessmentDate ?? new Date().toISOString(),
  assessments: assessmentGroup.assessments?.map(patchRemoteAssessment) || [],
  recommendations: assessmentGroup.recommendations ?? [],
  equipmentPrescription: assessmentGroup.equipmentPrescription ?? "",
  notes: assessmentGroup.notes || "",
  location: assessmentGroup.location || "",
  therapist: assessmentGroup.therapist || "",
});

export const patchRemoteAssessment = (
  assessment: RemoteAssessment
): Assessment =>
  defaultAssessment(
    assessment.id,
    assessment.assessmentGroupId,
    assessment.assessmentCriterion,
    assessment
  );

export const patchRemoteAssessmentGroup = (
  assessmentGroup: RemoteAssessmentGroup
): AssessmentGroup =>
  defaultAssessmentGroup(
    assessmentGroup.id,
    assessmentGroup.patient,
    assessmentGroup.description,
    assessmentGroup
  );

export { AssessmentCriteria };
