import { Auth } from "aws-amplify";

export const signOut = () => Auth.signOut();

export const getCurrentUser = async () => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch (e) {
    return null;
  }
};

export const getCurrentToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};
