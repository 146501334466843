import React from "react";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { Button } from "../../library";
import { Dark } from "../Layouts";

type Props = {
  error: Error;
};

const UnexpectedError: React.FC<Props> = ({ error }) => {
  // eslint-disable-next-line no-console
  console.log(error);

  return <Dark.Header>An error has occurred</Dark.Header>;
};

const ErrorResponse = () => <Dark.Header>Not found</Dark.Header>;

const is404 = (error: Error) =>
  isRouteErrorResponse(error) && error.status === 404;

const ErrorBoundary = () => {
  const error = useRouteError() as Error;

  const element = is404(error) ? (
    <ErrorResponse />
  ) : (
    <UnexpectedError error={error} />
  );

  return (
    <Dark.Container>
      <Dark.Content>
        {element}
        <Button variant="primary" onClick={() => (window.location.href = "/")}>
          Click here to try again
        </Button>
      </Dark.Content>
    </Dark.Container>
  );
};

export default ErrorBoundary;
