import { Assessment, AssessmentCriteria } from "../types";
import arm from "./assessments/arms";
import feet from "./assessments/feet";
import head from "./assessments/head";
import legsSitting from "./assessments/legsSitting";
import legsStanding from "./assessments/legsStanding";
import pelvis from "./assessments/pelvis";
import trunk from "./assessments/trunk";

type AssessmentSectionOptionValue = {
  label: string;
  value: number;
};

export type AssessmentSectionOption = {
  label: string;
  values: AssessmentSectionOptionValue[];
};

export type AssessmentSection = {
  name: string;
  image: string;
  options: AssessmentSectionOption[];
};

export type AssessmentArea = {
  name: string;
  criteria: AssessmentSection[];
  appliesTo?: AssessmentCriteria[];
};

const assessmentAreas: AssessmentArea[] = [
  head,
  trunk,
  pelvis,
  arm,
  legsStanding,
  legsSitting,
  feet,
];

export const getFilteredAssessmentAreas = (assessment: Assessment) =>
  assessmentAreas.filter(
    (a) =>
      a.appliesTo?.includes(assessment.assessmentCriterion.criterion) ?? true
  );

export default assessmentAreas;
