import React, { useCallback, useEffect, useMemo, useState } from "react";
import debouce from "lodash.debounce";

import {
  useCreateAssessmentGroup,
  useGetAssessmentGroups,
  useSearchParam,
} from "../../hooks";
import { Button, Nav, useModal, Slate, Form } from "../../library";
import Page from "../../components/Page";
import { FlaggedUserMenu } from "../../components/UserMenu";
import AssessmentGroupsList from "../../components/AssessmentGroupsList";
import NewAssessmentGroupModal from "../../components/NewAssessmentGroupModal";
import Loading from "../../components/Loading";
import { Inventory } from "../../components/Layouts";
import AssessmentsScreenSidepanel from "./AssessmentsSidepanel";

type SearchProps = {
  onChange: (value: string) => void;
  onClear: () => void;
  defaultValue: string;
};
const Search: React.FC<SearchProps> = ({ onChange, onClear, defaultValue }) => {
  const [value, setValue] = useState(defaultValue);

  const debouncedOnChange = useMemo(() => {
    return debouce(onChange, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (value) {
      debouncedOnChange(value);
    } else {
      onClear();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    return () => {
      debouncedOnChange.cancel();
    };
  });

  const handleClear = () => setValue("");

  return (
    <Form.Field className="has-addons">
      <Form.Control>
        <Form.Input placeholder="Search" value={value} onChange={setValue} />
      </Form.Control>
      <Form.Control>
        <Button onClick={handleClear}>
          <Button.Icon icon="xmark" />
        </Button>
      </Form.Control>
    </Form.Field>
  );
};

const AssessmentsScreen: React.FC = () => {
  const {
    set: selectGroup,
    value: group,
    clear: clearGroup,
  } = useSearchParam("group");

  const {
    set: setSearch,
    value: search,
    clear: clearSearch,
  } = useSearchParam("search");

  const { createAssessmentGroup, createAssessmentGroupState } =
    useCreateAssessmentGroup();

  const { assessmentGroups, state, loadMore } = useGetAssessmentGroups({
    search,
  });

  const { isModalOpen, closeModal, openModal } = useModal();

  const doCloseModal = useCallback(() => {
    closeModal();
    createAssessmentGroupState.reset?.();
  }, [closeModal, createAssessmentGroupState]);

  const header = (
    <Page.Header className="has-background-white">
      <Nav.Brand></Nav.Brand>
      <Nav.Start>
        <Nav.Item>
          <h1>Assessments</h1>
        </Nav.Item>
      </Nav.Start>
      <Nav.End>
        <FlaggedUserMenu />
      </Nav.End>
    </Page.Header>
  );

  const content = state.loading ? (
    <Loading />
  ) : state.error ? (
    <Slate.Error>An error occurred when loading your assessments</Slate.Error>
  ) : assessmentGroups ? (
    <>
      <AssessmentGroupsList
        selectGroup={selectGroup}
        selectedGroup={group}
        groups={assessmentGroups}
      />
      <div className="is-flex is-justify-content-center">
        {loadMore && <Button onClick={loadMore}>Load more</Button>}
      </div>
    </>
  ) : state.paused ? (
    <Slate.Offline />
  ) : null;

  return (
    <Page className="has-background-white">
      <Page.Main>
        {header}
        <Page.Body className="is-flex-grow-1">
          <Inventory>
            <Inventory.Content>
              <Inventory.Toolbar>
                <Search
                  defaultValue={search ?? ""}
                  onClear={clearSearch}
                  onChange={setSearch}
                />
                <Button variant="primary" onClick={openModal}>
                  <Button.Icon icon="plus" /> <span>New Assessment</span>
                </Button>
              </Inventory.Toolbar>

              <Inventory.Items>{content}</Inventory.Items>
            </Inventory.Content>
            <Inventory.SidePanel
              className="mobile-slide-up-tray"
              open={!!group}
            >
              {group && (
                <AssessmentsScreenSidepanel
                  onClose={clearGroup}
                  assessmentGroupId={group}
                />
              )}
            </Inventory.SidePanel>
          </Inventory>
        </Page.Body>
      </Page.Main>
      <NewAssessmentGroupModal
        isOpen={isModalOpen}
        createAssessmentGroupState={createAssessmentGroupState}
        createAssessmentGroup={createAssessmentGroup}
        closeModal={doCloseModal}
      />
    </Page>
  );
};

export default AssessmentsScreen;
