import React from "react";
import { Loader } from "../../library";

type Props = {
  loading?: boolean;
};

const Loading: React.FC<Props> = ({ loading = true }) =>
  loading ? (
    <span className="m-auto">
      <Loader className="mx-auto" size="small" />
    </span>
  ) : null;

export default Loading;
