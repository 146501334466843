import React from "react";
import classNames from "classnames";
import { Slate } from "../../library";

import Table from "../../library/table";
import { AssessmentGroup } from "../../types";
import { FriendlyDate } from "../../utils/date";
import UserIcon from "../UserIcon";

type AssessmentsListProps = {
  selectedGroup: string | null;
  groups: AssessmentGroup[];
  selectGroup: (id: string) => void;
};

const AssessmentGroupsList: React.FC<AssessmentsListProps> = ({
  selectGroup,
  selectedGroup,
  groups,
}) => {
  if (!groups.length) return <Slate.Empty>No assessments</Slate.Empty>;

  return (
    <div className="b-table">
      <div className="table-wrapper has-mobile-cards">
        <Table className="is-clickable is-vertical-align-middle">
          <thead>
            <tr>
              <th></th>
              <th>Patient Name</th>
              <th>Description</th>
              <th>Assessment Date</th>
            </tr>
          </thead>
          <tbody>
            {groups.map((g) => (
              <tr
                className={classNames({
                  "is-selected": g.id === selectedGroup,
                })}
                onClick={() => selectGroup(g.id)}
                key={g.id}
              >
                <td className="is-image-cell">
                  <UserIcon name={g.patient} />
                </td>
                <td data-label="Patient Name">{g.patient}</td>
                <td data-label="Description">{g.description}</td>
                <td data-label="Assessment Date">
                  <FriendlyDate date={g.assessmentDate} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AssessmentGroupsList;
