import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

const Brand: React.FC<PropsWithChildren<ClassNameProp>> = ({
  children,
  className,
}) => {
  return (
    <div className={classNames("navbar-brand", className)}>{children}</div>
  );
};

export default Brand;
