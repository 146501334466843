import { QueryClient } from "@tanstack/react-query";

import setDefaultCreateAssessmentMutation from "./createAssessment";
import setDefaultCreateAssessmentGroupMutation from "./createAssessmentGroup";
import setDefaultUpdateAssessmentMutation from "./updateAssessment";
import setDefaultUpdateAssessmentGroupMutation from "./updateAssessmentGroup";

const allMutationInitilizers = [
  setDefaultCreateAssessmentGroupMutation,
  setDefaultCreateAssessmentMutation,
  setDefaultUpdateAssessmentMutation,
  setDefaultUpdateAssessmentGroupMutation,
];

const initializeMutationDefaults = (queryClient: QueryClient) => {
  allMutationInitilizers.map((fn) => fn(queryClient));
};

export default initializeMutationDefaults;
