import classNames from "classnames";
import React from "react";

type Props = {
  value: string;
  max: number;
};

const RemainingCharacters: React.FC<Props> = ({ value, max }) => {
  const remaining = Math.max(max - value.length, 0);
  return (
    <span
      className={classNames("is-block has-text-right", {
        "has-text-danger": remaining === 0,
      })}
    >
      Characters left: {remaining}
    </span>
  );
};

export default RemainingCharacters;
