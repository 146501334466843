import { useSearchParams } from "react-router-dom";

const useSearchParam = (name: string) => {
  const [search, setSearch] = useSearchParams();

  const value = search.get(name);

  return {
    value,
    set: (value: string) => {
      search.set(name, value);
      setSearch(search);
    },
    clear: () => {
      search.delete(name);
      setSearch(search);
    },
  };
};

export default useSearchParam;
