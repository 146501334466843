const hashCode = (s: string) =>
  Array.from(s).reduce(
    (hash, c) => (Math.imul(31, hash) + c.charCodeAt(0)) | 0,
    0
  );

const generateColor = (vary = "") => {
  return `hsla(${hashCode(vary) % 360}, 60%,  60%, 0.8)`;
};

export default generateColor;
