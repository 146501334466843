import React from "react";

import { Button, Icon } from "../../library";
import { Assessment, AssessmentCriteria } from "../../types";
import { Link } from "react-router-dom";
import { getAssessmentStatus, getIncompleteAssessmentCriteria } from "./utils";
import classNames from "classnames";
import { Inventory } from "../Layouts";

type AssessmentStatusIconProps = {
  status?: {
    icon: string;
    label: string;
    variant: string;
  };
};

const AssessmentStatusIcon: React.FC<AssessmentStatusIconProps> = ({
  status = getAssessmentStatus(),
}) => (
  <Icon
    title={status.label}
    className={classNames(`mr-3 has-text-${status.variant}`)}
    icon={status.icon}
  />
);

type Props = {
  assessments: Assessment[];
  onStart: (criterion: AssessmentCriteria) => void;
};

const AssessmentsList: React.FC<Props> = ({ assessments, onStart }) => {
  const incomplete = getIncompleteAssessmentCriteria(assessments);

  return (
    <div className="mb-3">
      {assessments.map((a) => (
        <Item key={a.id} assessment={a} />
      ))}

      {incomplete.map((criterion) => (
        <IncompleteItem
          key={criterion}
          criterion={criterion}
          onStart={onStart}
        />
      ))}
    </div>
  );
};

type ItemProps = {
  assessment: Assessment;
};

const Item: React.FC<ItemProps> = ({ assessment }) => {
  const status = getAssessmentStatus(assessment);

  return (
    <Inventory.SidePanel.Item>
      <AssessmentStatusIcon status={status} />
      <span>
        <h4>{assessment.assessmentCriterion.criterion}</h4>
        <p>{assessment.assessmentCriterion.details}</p>
      </span>

      <Link className="button ml-auto" to={`/assessment/${assessment.id}`}>
        {status.action}
      </Link>
    </Inventory.SidePanel.Item>
  );
};

type IncompleteItemProps = {
  criterion: AssessmentCriteria;
  onStart: (criterion: AssessmentCriteria) => void;
};

const IncompleteItem: React.FC<IncompleteItemProps> = ({
  criterion,
  onStart,
}) => {
  return (
    <Inventory.SidePanel.Item>
      <AssessmentStatusIcon />
      <span>
        <h4>{criterion}</h4>
      </span>
      <Button onClick={() => onStart(criterion)} className="ml-auto">
        Start
      </Button>
    </Inventory.SidePanel.Item>
  );
};

export default AssessmentsList;
