import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

type Props = {
  dropdown?: boolean | "hoverable";
  onClick?: () => void;
} & ClassNameProp;

const Item = React.forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ className, children, dropdown = false, onClick }, ref) => {
    const cls = classNames(
      "navbar-item",
      {
        "has-dropdown": dropdown,
        "is-hoverable": dropdown === "hoverable",
      },
      className
    );

    return (
      <div ref={ref} onClick={onClick} className={cls}>
        {children}
      </div>
    );
  }
);

export default Item;
