import React from "react";
import { Icons } from "../../constants";
import { useDeleteAssessmentGroupMutation } from "../../graphql/generated";
import { useConfirmation, Confirm, Button } from "../../library";
import { queryClient } from "../../ReactQuery";

type Props = {
  id: string;
};
const DeleteAssessmentGroup: React.FC<Props> = ({ id }) => {
  const { mutate } = useDeleteAssessmentGroupMutation({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
  });

  const { open, ...props } = useConfirmation({
    onConfirm: () => mutate({ id }),
  });

  return (
    <>
      <Confirm {...props} variant="danger" title="Delete assessment?">
        <p>Are you sure you want to delete this assessment?</p>
        <p>This action cannot be undone.</p>
      </Confirm>
      <Button title="Delete assessment" onClick={open}>
        <Button.Icon icon={Icons.delete} />
      </Button>
    </>
  );
};

export default DeleteAssessmentGroup;
