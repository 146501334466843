import { assessmentCriteria, getFilteredAssessmentAreas } from "../../data";
import { Assessment, AssessmentCriteria } from "../../types";

export const getIncompleteAssessmentCriteria = (
  assessments: Assessment[]
): AssessmentCriteria[] =>
  assessmentCriteria
    .map(({ id }) => id)
    .filter(
      (c) => !assessments.find((a) => a.assessmentCriterion.criterion === c)
    );

const AssessmentState = {
  NOT_STARTED: {
    icon: "circle-minus",
    label: "Not started",
    variant: "grey-light",
    action: "Start",
  },
  STARTED: {
    icon: "list-check",
    label: "Started",
    variant: "success",
    action: "Continue",
  },
  COMPLETE: {
    icon: "check",
    label: "Completed",
    variant: "success",
    action: "Review",
  },
} as const;

export const getAssessmentStatus = (assessment?: Assessment) => {
  if (!assessment) return AssessmentState.NOT_STARTED;

  const flattenedCriteria = getFilteredAssessmentAreas(assessment).flatMap(
    (a) => a.criteria
  );

  return assessment.assessmentScores.length === flattenedCriteria.length
    ? AssessmentState.COMPLETE
    : AssessmentState.STARTED;
};
