import htmlElementComponent from "../htmlElementComponent";

export const InputUncontrolled = htmlElementComponent({
  classes: "input",
  as: "input",
});

export const TextareaUncontrolled = htmlElementComponent({
  classes: "textarea",
  as: "textarea",
});
