import React from "react";

import { Nav, Button } from "../../library";
import NewAssessmentModal, {
  useNewAssessmentModal,
} from "../../components/NewAssessmentModal";
import { Dark } from "../../components/Layouts";
import Logo from "../../components/Logo";
import { CreateNewAssessmentFn } from "../../types";
import features from "../../features";
import { FlaggedUserMenu } from "../UserMenu";

type LandingProps = {
  createAssessment: CreateNewAssessmentFn;
};

const Landing: React.FC<LandingProps> = ({ createAssessment }) => {
  const { openModal, ...rest } = useNewAssessmentModal();

  return (
    <Dark.Container>
      <nav
        className="navbar has-background-feature"
        role="navigation"
        aria-label="main navigation"
      >
        {features.accounts && (
          <Nav.End>
            <FlaggedUserMenu />
          </Nav.End>
        )}
      </nav>
      <Dark.Content>
        <span className="mb-2">
          <Logo />
        </span>
        <Dark.Header>CABA Assessment</Dark.Header>

        <p className="mb-1">&copy; Dr Frances George, 2021</p>

        <p className="is-italic mb-6">University of Leeds</p>

        <Button variant="primary" onClick={openModal}>
          Start assessment
        </Button>

        <NewAssessmentModal createAssessment={createAssessment} {...rest} />
      </Dark.Content>
    </Dark.Container>
  );
};

export default Landing;
