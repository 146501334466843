import React, { PropsWithChildren } from "react";
import Nav from ".";
import { ClassNameProp } from "../utils";
import MenuButton from "./MenuButton";

const MenuToggle: React.FC<PropsWithChildren<ClassNameProp>> = () => (
  <Nav.Item className="is-hidden-desktop pr-0">
    <MenuButton />
  </Nav.Item>
);

export default MenuToggle;
