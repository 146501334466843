import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp } from "../utils";

type Props = ClassNameProp;

const Table: React.FC<PropsWithChildren<Props>> = ({ children, className }) => (
  <table
    className={classNames(
      "table is-fullwidth is-striped is-hoverable",
      className
    )}
  >
    {children}
  </table>
);

export default Object.assign(Table, {});
