import React, { PropsWithChildren } from "react";
import { Button } from "../../library";
import { AssessmentCriteria } from "../../types";
import { AssessmentCriterion } from "./types";

type Props = {
  criterion: AssessmentCriteria | null;
  details: string;
  onClick: (value: AssessmentCriterion) => void;
  disabled?: boolean;
};

const Select: React.FC<PropsWithChildren<Props>> = ({
  onClick,
  children,
  disabled,
  criterion,
  details,
  ...props
}) => {
  const isDisabled = !criterion || disabled;

  return (
    <>
      <Button
        variant="primary"
        disabled={isDisabled}
        onClick={() =>
          isDisabled
            ? undefined
            : onClick({
                criterion,
                details,
              })
        }
        {...props}
      >
        {children}
      </Button>
    </>
  );
};

type BackProps = {
  criterion: AssessmentCriteria | null;
  setCriterion: (criterion: null) => void;
};

const Back: React.FC<BackProps> = ({ criterion, setCriterion }) =>
  criterion ? <Button onClick={() => setCriterion(null)}>Back</Button> : null;

export default Object.assign(
  {},
  {
    Back,
    Select,
  }
);
