function assertDefined<T>(
  entity?: T,
  message = "Entity cannot be undefined"
): T {
  if (entity === undefined) {
    throw new Error(message);
  }
  return entity;
}

export default assertDefined;
