import React, { useCallback, useEffect } from "react";
import { useNextPrev } from "../../hooks";
import { Banner, Button, Modal, Switch } from "../../library";
import {
  CreateNewAssessmentFn,
  CreateNewAssessmentGroupFn,
  RemoteOperationState,
} from "../../types";
import AssessmentCriteriaSelection, {
  useAssessmentCriteriaSelection,
} from "../AssessmentCriteriaSelection";
import NewAssessmentGroupDetailsForm, {
  useNewAssessmentGroupDetailsForm,
} from "../NewAssessmentGroupDetailsForm";

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  createAssessmentGroup: CreateNewAssessmentGroupFn;
  createAssessmentGroupState?: RemoteOperationState;
};

const NewAssessmentGroupModal: React.FC<Props> = ({
  closeModal,
  isOpen,
  createAssessmentGroup,
  createAssessmentGroupState,
}) => {
  const {
    register,
    errors,
    handleSubmit,
    getValues,
    reset: resetForm,
  } = useNewAssessmentGroupDetailsForm();

  const { current, next, prev, reset } = useNextPrev([1, 2]);

  useEffect(() => {
    reset();
    resetForm();
  }, [isOpen, resetForm, reset]);

  const createAssessment: CreateNewAssessmentFn = useCallback(
    (assessmentCriteria) =>
      createAssessmentGroup(getValues(), assessmentCriteria),
    [createAssessmentGroup, getValues]
  );

  const {
    criterion,
    setCriterion,
    details,
    setDetails,
    reset: resetAssessmentCriteria,
  } = useAssessmentCriteriaSelection();

  useEffect(resetAssessmentCriteria, [isOpen, resetAssessmentCriteria]);

  return (
    <Modal
      aria-label="New assessment group"
      isOpen={isOpen}
      onClose={closeModal}
    >
      <Switch caseId={current}>
        <Modal.Header>
          <Switch.Case caseId={1}>Assessment details</Switch.Case>
          <Switch.Case caseId={2}>Choose assessment criteria</Switch.Case>
        </Modal.Header>
        {createAssessmentGroupState?.error && (
          <Banner>There was an error creating the assessment</Banner>
        )}
        <Modal.Body>
          <Switch.Case caseId={1}>
            <NewAssessmentGroupDetailsForm {...{ register, errors }} />
          </Switch.Case>
          <Switch.Case caseId={2}>
            <AssessmentCriteriaSelection.Options
              criterion={criterion}
              setCriterion={setCriterion}
            />
            <AssessmentCriteriaSelection.Details
              criterion={criterion}
              setDetails={setDetails}
            />
          </Switch.Case>
        </Modal.Body>
        <Modal.Footer>
          <Switch.Case caseId={1}>
            {next ? (
              <Button variant="primary" onClick={handleSubmit(next)}>
                Next
              </Button>
            ) : (
              <span></span>
            )}
          </Switch.Case>
          <Switch.Case caseId={2}>
            {criterion ? (
              <AssessmentCriteriaSelection.Button.Back
                criterion={criterion}
                setCriterion={setCriterion}
              />
            ) : prev ? (
              <Button onClick={prev}>Back</Button>
            ) : (
              <span></span>
            )}

            <AssessmentCriteriaSelection.Button.Select
              disabled={createAssessmentGroupState?.loading}
              criterion={criterion}
              details={details}
              onClick={createAssessment}
            >
              {createAssessmentGroupState?.loading ? "Creating..." : "Start"}
            </AssessmentCriteriaSelection.Button.Select>
          </Switch.Case>
        </Modal.Footer>
      </Switch>
    </Modal>
  );
};

export default NewAssessmentGroupModal;
