import classNames from "classnames";
import React from "react";
import { assessmentCriteria } from "../../data";
import { Button } from "../../library";
import { AssessmentCriteria } from "../../types";

type Props = {
  criterion: AssessmentCriteria | null;
  setCriterion: (criterion: AssessmentCriteria | null) => void;
};

const Options: React.FC<Props> = ({ criterion, setCriterion }) => {
  return criterion ? null : (
    <div className="is-flex is-justify-content-center is-flex-wrap-wrap">
      {assessmentCriteria.map((c) => (
        <Button
          className={classNames("m-3 p-6 is-flex-grow-1 is-fullwidth-mobile")}
          key={c.id}
          onClick={() => setCriterion(c.id)}
        >
          {c.label}
        </Button>
      ))}
    </div>
  );
};

export default Options;
