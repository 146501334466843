import { AssessmentCriteria } from "../types";

const assessmentDetails = [
  {
    value: "In existing equipment",
  },
  {
    value: "New provision",
  },
  {
    appliesTo: [AssessmentCriteria.Sitting],
    value: "Box sitting",
  },
  {
    appliesTo: [AssessmentCriteria.Standing, AssessmentCriteria.Lying],
    value: "Floor",
  },
];

export default assessmentDetails;
