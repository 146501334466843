import React from "react";

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import AssessmentResultsPdf from "./AssessmentResultsPdf";
import logo from "../../images/logo-white.jpg";
import colors from "./colors";
import { Assessment, AssessmentGroup } from "../../types";
import AssessmentRecommendationsPdf from "./AssessmentRecommendationsPdf";
import {
  mergeAssessmentData,
  mergeRecommendationData,
} from "../Assessment/utils";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";
import AssessmentGroupDetailsPdf from "./AssessmentGroupDetailsPdf";
import { formatDateTime } from "../../utils/date";

Font.register({
  family: "Nunito",
  src: "https://fonts.gstatic.com/s/nunito/v25/XRXI3I6Li01BKofiOc5wtlZ2di8HDLshdTA3j6zbXWjgevT5.woff2",
});

const styles = StyleSheet.create({
  heading: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 20,
  },
  page: {
    padding: 20,
    fontFamily: "Nunito",
    fontSize: 9,
  },
  columns: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "auto",
    height: "auto",
  },
  pictureColumn: {
    width: 230,
    marginLeft: 10,
    paddingLeft: 20,
  },
  column50: {
    width: "45%",
  },
  logo: {
    width: 60,
    height: "auto",
    marginBottom: 10,
  },
  largeTag: {
    padding: 10,
    marginBottom: 20,
    backgroundColor: colors.primary,
    borderRadius: 6,
    color: colors.white,
    textAlign: "center",
    width: 150,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
});

type AssessmentSummaryPdfProps = {
  assessment: Assessment;
};

const AssessmentSummaryPdf: React.FC<AssessmentSummaryPdfProps> = ({
  assessment,
}) => {
  const assessmentResults = mergeAssessmentData(assessment);

  const score = assessmentResults.reduce((a, b) => a + b.score, 0);

  return (
    <View style={styles.columns}>
      <View>
        <Text style={styles.heading}>
          {formatAssessmentCriterion(assessment.assessmentCriterion)}
        </Text>
        <Text style={styles.largeTag}>Overall score: {score}</Text>
        <AssessmentResultsPdf assessmentResults={assessmentResults} />
      </View>
      <View style={styles.pictureColumn}>
        {assessment.sketches.map((src, idx) => (
          <Image style={styles.image} src={src.image} key={idx} />
        ))}
      </View>
    </View>
  );
};

type Props = {
  assessmentGroup: AssessmentGroup;
};

const AssessmentGroupSummaryPdf: React.FC<Props> = ({ assessmentGroup }) => {
  const recommendations = mergeRecommendationData(
    assessmentGroup.recommendations
  );

  const footer = (
    <View style={styles.footer}>
      <Text>{assessmentGroup.patient}</Text>
      <Text>{formatDateTime(assessmentGroup.assessmentDate)}</Text>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.columns}>
          <Image style={styles.logo} src={logo} />
        </View>
        <AssessmentGroupDetailsPdf assessmentGroup={assessmentGroup} />
        {footer}
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.heading}>Assessment Outcomes</Text>
        <AssessmentRecommendationsPdf
          equipmentPrescription={assessmentGroup.equipmentPrescription}
          recommendations={recommendations}
        />
        {footer}
      </Page>

      {assessmentGroup.assessments.map((assessment) => (
        <Page size="A4" key={assessment.id} style={styles.page}>
          <AssessmentSummaryPdf assessment={assessment} />
          {footer}
        </Page>
      ))}
    </Document>
  );
};

export default AssessmentGroupSummaryPdf;
