import { Amplify } from "aws-amplify";

import config from "../config";
import features from "../features";

if (features.accounts) {
  Amplify.configure({
    API: {
      region: config.AWS_REGION,
      aws_appsync_graphqlEndpoint: config.GRAPHQL_ENDPOINT,
      aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    },
    Auth: {
      region: config.AWS_REGION,
      userPoolId: config.COGNITO_USER_POOL_ID,
      userPoolWebClientId: config.COGNITO_USER_POOL_APP_CLIENT_ID,
    },
  });
}
