import React, { PropsWithChildren } from "react";

import { QueryClient, MutationCache } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import initializeMutationDefaults from "./graphql/mutations";

export const queryClient: QueryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: 2000,
      retry: 0,
      // Avoid get before create when we first come back online
      refetchOnReconnect: () => !queryClient.isMutating(),
    },
  },
  // configure global cache callbacks to show toast notifications
  mutationCache: new MutationCache({
    // onSuccess: console.log,
    // eslint-disable-next-line no-console
    onError: console.error,
  }),
});

initializeMutationDefaults(queryClient);

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const ReactQuery: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {
        // resume mutations after initial restore from localStorage was successful
        queryClient.resumePausedMutations().then(() => {
          queryClient.invalidateQueries();
        });
      }}
    >
      {children}
      <ReactQueryDevtools initialIsOpen />
    </PersistQueryClientProvider>
  );
};

export default ReactQuery;
