import React from "react";
import ReactMarkdown from "react-markdown";
import help from "./HelpText";
import copyright from "./CopyrightText";
import { EmailLink } from "../EmailLink";

const HelpContent: React.FC = () => {
  return (
    <div className="content">
      <ReactMarkdown>{help}</ReactMarkdown>
      <ReactMarkdown>{copyright}</ReactMarkdown>

      <h3>Get in touch</h3>
      <EmailLink />
    </div>
  );
};

export default HelpContent;
