import LabelGroups from "./_labels";

const feet = {
  name: "Feet",
  criteria: [
    {
      name: "Inversion / Eversion (L)",
      image: "/assessment-images/feet-inversion-eversion-left.png",
      options: [
        {
          label: "Eversion",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Inversion",
          values: LabelGroups.FIVE_TWENTY_PLUS,
        },
      ],
    },
    {
      name: "Plantarflexion / Dorsiflexion (L)",
      image: "/assessment-images/feet-plantarflexion-dorsiflexion-left.png",
      options: [
        {
          label: "Plantarflexion",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Dorsiflexion",
          values: LabelGroups.FIVE_FIFTEEN_PLUS,
        },
      ],
    },
    {
      name: "Inversion / Eversion (R)",
      image: "/assessment-images/feet-inversion-eversion-right.png",
      options: [
        {
          label: "Inversion",
          values: LabelGroups.FIVE_TWENTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Eversion",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
      ],
    },
    {
      name: "Plantarflexion / Dorsiflexion (R)",
      image: "/assessment-images/feet-plantarflexion-dorsiflexion-right.png",
      options: [
        {
          label: "Plantarflexion",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Dorsiflexion",
          values: LabelGroups.FIVE_FIFTEEN_PLUS,
        },
      ],
    },
  ],
};

export default feet;
