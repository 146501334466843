import React, { PropsWithChildren } from "react";
import { Button, Modal } from "../../library";
import { Variant } from "../utils";

type Props = {
  isOpen: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  title?: string;
  variant?: Variant;
  buttonLabel?: React.ReactNode;
};

const Confirm: React.FC<PropsWithChildren<Props>> = ({
  variant = "primary",
  children,
  onConfirm,
  onCancel,
  isOpen,
  title = "Are you sure?",
}) => {
  return (
    <>
      <Modal isOpen={isOpen} portal onClose={onCancel}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body className="has-text-centered content is-flex is-flex-direction-column is-justify-content-center">
          {children}
        </Modal.Body>
        <Modal.Footer>
          {onCancel && <Button onClick={onCancel}>Cancel</Button>}
          {onConfirm && (
            <Button variant={variant} onClick={onConfirm}>
              Confirm
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Confirm;
