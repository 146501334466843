export const StateStorageKeys = {
  FullAssessment: "full-assessment-v2",
  Therapist: "therapist",
} as const;

export const Colors = {
  green: "#168a1e",
  yellow: "#f2e555",
  orange: "#ef790b",
  red: "#c92f2f",
} as const;

export const Icons = {
  home: "home",
  edit: "pencil",
  delete: "trash",
  exit: "arrow-right-from-bracket",
  details: "circle-info",
  recommendations: "comment-medical",
  sketch: "pencil",
  export: "file-export",
  summary: "file",
  patient: "user",
  equipmentPrescription: "user-doctor",
  done: "check",
} as const;

export const EmailAddress = "caba.assessment@gmail.com";
