import classNames from "classnames";
import React, { PropsWithChildren } from "react";
import { ClassNameProp, Variant } from "../utils";

export type IconProps = ClassNameProp & {
  icon: string;
  variant?: Variant;
  size?: "small" | "large";
  title?: string;
};

const Icon: React.FC<PropsWithChildren<IconProps>> = ({
  icon,
  className,
  size,
  variant,
  title,
}) => {
  return (
    <span title={title} className={classNames(className, "icon")}>
      <i
        className={classNames(`fa fa-${icon}`, {
          "fa-sm": size === "small",
          "fa-lg": size === "large",
          [`has-text-${variant}`]: variant,
        })}
      />
    </span>
  );
};

export default Icon;
