import { AssessmentCriteria } from "../../types";
import LabelGroups from "./_labels";

const legsStanding = {
  name: "Legs",
  appliesTo: [AssessmentCriteria.Standing, AssessmentCriteria.Lying],
  criteria: [
    {
      name: "Flexion / Extension (L)",
      image: "/assessment-images/leg-flexion-extension-left.png",
      options: [
        {
          label: "Flexion",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Extension",
          values: LabelGroups.FIVE_FIFTEEN_PLUS,
        },
      ],
    },
    {
      name: "Abduction / Adduction (L)",
      image: "/assessment-images/leg-adduction-abduction-left.png",
      options: [
        {
          label: "Abduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Adduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation (L)",
      image: "/assessment-images/leg-rotation-left.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
      ],
    },
    {
      name: "Flexion / Extension (R)",
      image: "/assessment-images/leg-flexion-extension-right.png",
      options: [
        {
          label: "Extension",
          values: LabelGroups.FIVE_FIFTEEN_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Flexion",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Abduction / Adduction (R)",
      image: "/assessment-images/leg-adduction-abduction-right.png",
      options: [
        {
          label: "Adduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Abduction",
          values: LabelGroups.FIVE_TWENTY_SIXTY_PLUS,
        },
      ],
    },
    {
      name: "Rotation (R)",
      image: "/assessment-images/leg-rotation-right.png",
      options: [
        {
          label: "Left",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
        {
          label: "Neutral",
          values: LabelGroups.NEUTRAL,
        },
        {
          label: "Right",
          values: LabelGroups.FIVE_TWENTY_FORTY_PLUS,
        },
      ],
    },
  ],
};

export default legsStanding;
