import { QueryClient } from "@tanstack/react-query";
import { AssessmentGroup } from "../../types";

import {
  useUpdateAssessmentGroupMutation,
  useGetAssessmentGroupQuery,
} from "../generated";
import { removeStaleMutationsFromCache } from "../utils";

const mutationKey = useUpdateAssessmentGroupMutation.getKey();

const setDefaultUpdateAssessmentGroup = (queryClient: QueryClient) => {
  queryClient.setMutationDefaults(mutationKey, {
    mutationFn: async (data: AssessmentGroup) =>
      useUpdateAssessmentGroupMutation.fetcher(data, {})(),
    onMutate: async (assessmentGroup: AssessmentGroup) => {
      removeStaleMutationsFromCache(
        queryClient,
        mutationKey,
        assessmentGroup.id
      );

      const queryKey = useGetAssessmentGroupQuery.getKey({
        id: assessmentGroup.id,
      });

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, {
        getAssessmentGroup: assessmentGroup,
      });

      // Return a context with the data
      return { previousData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err, assessmentGroup: AssessmentGroup, context) => {
      const queryKey = useGetAssessmentGroupQuery.getKey({
        id: assessmentGroup.id,
      });
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, assessmentGroup: AssessmentGroup) => {
      const queryKey = useGetAssessmentGroupQuery.getKey({
        id: assessmentGroup.id,
      });
      queryClient.invalidateQueries(queryKey);
      queryClient.invalidateQueries(["GetAssessmentGroups.infinite"]);
    },
  });
};

export default setDefaultUpdateAssessmentGroup;
