import React from "react";

import { FieldError } from "react-hook-form";

import Form from "../../library/form";
import errorMessages from "./errorMessages";

const formatErrorMessage = (type: string, field: string) =>
  (errorMessages[type] ?? errorMessages.default)(field);

const FormValidationError: React.FC<{ field: string; error?: FieldError }> = ({
  field,
  error,
}) =>
  error ? (
    <Form.Error>{formatErrorMessage(error.type, field)}</Form.Error>
  ) : null;

export default FormValidationError;
