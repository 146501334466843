import React, { useCallback } from "react";

import AssessmentComponent from "../../components/Assessment";
import Landing from "../../components/Landing";
import { StateStorageKeys } from "../../constants";
import { useStickyState, useUpdateAssessmentHelpers } from "../../hooks";
import {
  Assessment,
  CreateNewAssessmentFn,
  defaultAssessment,
  defaultPatient,
} from "../../types";

const AssessmentScreen: React.FC = () => {
  const [assessment, setAssessment] = useStickyState<Assessment | null>(
    null,
    StateStorageKeys.FullAssessment
  );

  const createAssessment: CreateNewAssessmentFn = useCallback(
    (assessmentCriterion, retainedAssessmentFields) => {
      const therapist =
        retainedAssessmentFields?.patient?.therapist ||
        assessment?.patient?.therapist;

      setAssessment(
        defaultAssessment("local", "group", assessmentCriterion, {
          ...retainedAssessmentFields,
          patient: defaultPatient({
            ...retainedAssessmentFields?.patient,
            therapist,
          }),
        })
      );
    },
    [assessment?.patient?.therapist, setAssessment]
  );

  if (assessment) {
    return (
      <AssessmentComponentWrapper
        createAssessment={createAssessment}
        setAssessment={setAssessment}
        selectedAssessment={assessment}
      />
    );
  }

  return <Landing createAssessment={createAssessment} />;
};

type AssessmentComponentWrapperProps = {
  selectedAssessment: Assessment;
  setAssessment: (assessment: Assessment | null) => void;
  createAssessment: CreateNewAssessmentFn;
};

const AssessmentComponentWrapper: React.FC<AssessmentComponentWrapperProps> = ({
  selectedAssessment,
  setAssessment,
  createAssessment,
}) => {
  const updateSelectedAssessment = useUpdateAssessmentHelpers((fn) =>
    setAssessment(fn(selectedAssessment))
  );

  return (
    <AssessmentComponent
      createAssessment={createAssessment}
      clearSelectedAssessment={() => setAssessment(null)}
      updateSelectedAssessment={updateSelectedAssessment}
      selectedAssessment={selectedAssessment}
    />
  );
};

export default AssessmentScreen;
