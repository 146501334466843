import React, { useState } from "react";
import { Button, Modal, Switch, useModal } from "../../library";
import AssessmentCriteriaSelection, {
  AssessmentCriterion,
  useAssessmentCriteriaSelection,
} from "../AssessmentCriteriaSelection";
import ExportPdf from "../ExportPdf";
import { AllAssessmentData, Patient } from "../../types";
import useWizard from "./useWizard";
import { AssessmentPdf } from "../AssessmentSummaryPdf";

const formatFilename = (
  patientDetails: Patient,
  assessmentCriterion: AssessmentCriterion
) =>
  `caba_${patientDetails.name || "anonymous"}_${
    assessmentCriterion.criterion
  }_${patientDetails.date}.pdf`;

type CompleteAssessmentProps = {
  completeAnotherAssessment: (assessment: AssessmentCriterion) => void;
  exitAssessment: () => void;
} & AllAssessmentData;

const CompleteAssessment: React.FC<CompleteAssessmentProps> = ({
  assessmentCriterion,
  assessmentResults,
  patientDetails,
  completeAnotherAssessment,
  exitAssessment,
  assessmentImages,
  recommendations,
  equipmentPrescription,
}) => {
  const { criterion, setCriterion, details, setDetails, reset } =
    useAssessmentCriteriaSelection();

  const { isModalOpen, closeModal, openModal } = useModal({ onClose: reset });

  const { page, nextPage, previousPage, resetPage } = useWizard(3);

  const [hasExported, setHasExported] = useState(false);

  const exitModal = () => {
    resetPage();
    closeModal();
    setHasExported(false);
  };

  const doExitAssessment = () => {
    exitAssessment();
    exitModal();
  };

  const doCompleteAnotherAssessment = (assessment: AssessmentCriterion) => {
    completeAnotherAssessment(assessment);
    exitModal();
  };

  return (
    <>
      <Button variant="primary" onClick={openModal}>
        Complete
      </Button>

      <Switch caseId={page}>
        <Modal isOpen={isModalOpen} portal onClose={exitModal}>
          <Modal.Header>
            <Switch.Case caseId={1}>Export assessment</Switch.Case>
            <Switch.Case caseId={2}>Reassess this patient?</Switch.Case>
            <Switch.Case caseId={3}>Choose assessment criteria</Switch.Case>
          </Modal.Header>
          <Modal.Body>
            <Switch.Case caseId={1}>
              <div className="is-flex is-align-items-center is-flex-direction-column">
                <ExportPdf
                  filename={formatFilename(patientDetails, assessmentCriterion)}
                  onExport={() => setHasExported(true)}
                >
                  <AssessmentPdf
                    equipmentPrescription={equipmentPrescription}
                    recommendations={recommendations}
                    assessmentImages={assessmentImages}
                    assessmentCriterion={assessmentCriterion}
                    patientDetails={patientDetails}
                    assessmentResults={assessmentResults}
                  />
                </ExportPdf>
              </div>
            </Switch.Case>
            <Switch.Case caseId={2}>
              <div className="is-flex is-flex-direction-column">
                {nextPage && (
                  <Button className="m-3" variant="primary" onClick={nextPage}>
                    Complete another assessment
                  </Button>
                )}
                <Button
                  className="m-3"
                  variant="danger"
                  onClick={doExitAssessment}
                >
                  No thanks, I'm done
                </Button>
              </div>
            </Switch.Case>
            <Switch.Case caseId={3}>
              <AssessmentCriteriaSelection.Options
                criterion={criterion}
                setCriterion={setCriterion}
              />
              <AssessmentCriteriaSelection.Details
                criterion={criterion}
                setDetails={setDetails}
              />
            </Switch.Case>
          </Modal.Body>
          <Modal.Footer>
            <Switch.Case caseId={1}>
              {nextPage && (
                <Button onClick={nextPage}>
                  {hasExported ? "Next" : "Skip"}
                </Button>
              )}
            </Switch.Case>
            <Switch.Case caseId={2}>
              {previousPage && <Button onClick={previousPage}>Back</Button>}
            </Switch.Case>
            <Switch.Case caseId={3}>
              <AssessmentCriteriaSelection.Button.Select
                criterion={criterion}
                details={details}
                onClick={doCompleteAnotherAssessment}
              >
                Start next assessment
              </AssessmentCriteriaSelection.Button.Select>
            </Switch.Case>
          </Modal.Footer>
        </Modal>
      </Switch>
    </>
  );
};

export default CompleteAssessment;
