import { createContext, useContext } from "react";

const MenuContext = createContext({
  isOpen: false,
  toggle: (state?: boolean): void => {
    // default
  },
});

export const useMenu = () => useContext(MenuContext);

export default MenuContext;
