import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.scss";
import App from "./App";

import "./init";
import AuthenticatedApp from "./AuthenticatedApp";
import features from "./features";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    {features.accounts ? <AuthenticatedApp /> : <App />}
  </React.StrictMode>
);
