import React, { PropsWithChildren } from "react";
import Button from "../button";
import Icon from "../icon";

type SlateProps = {
  icon: string;
};

const Slate: React.FC<PropsWithChildren<SlateProps>> = ({ icon, children }) => (
  <span className="m-auto has-text-centered">
    <p className="mb-2">
      <Icon icon={icon} />
    </p>
    {children}
  </span>
);

type ErrorProps = {
  retry?: () => void;
};

const Error: React.FC<PropsWithChildren<ErrorProps>> = ({
  children,
  retry,
}) => (
  <Slate icon="circle-exclamation">
    <p>{children}</p>
    {retry && <Button onClick={retry}>Retry</Button>}
  </Slate>
);

const Empty: React.FC<PropsWithChildren> = ({ children }) => (
  <Slate icon="box-open">
    <p>{children}</p>
  </Slate>
);

const Offline: React.FC = () => (
  <Slate icon="triangle-exclamation">
    <p>It looks like your device is not connected to a network</p>
  </Slate>
);

export default Object.assign(Slate, { Error, Empty, Offline });
