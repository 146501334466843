import classNames from "classnames";
import React, { PropsWithChildren, useContext } from "react";
import Icon from "../icon";
import { ClassNameProp } from "../utils";
import MenuContext from "./MenuContext";

type Props = {
  icon?: string;
} & ClassNameProp;

const MenuButton: React.FC<PropsWithChildren<Props>> = ({
  className,
  icon = "bars",
}) => {
  const { isOpen, toggle } = useContext(MenuContext);

  return (
    <button
      className={classNames("button is-ghost is-hidden-desktop", className)}
      aria-label="menu"
      aria-expanded={isOpen ? "true" : "false"}
      onClick={(e) => {
        e.stopPropagation();
        toggle();
      }}
    >
      <Icon icon={icon} />
    </button>
  );
};

export default MenuButton;
