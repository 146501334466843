import Form, { FormProps } from "./Form";
import Input, { FormInputProps } from "./Input";
import { InputUncontrolled, TextareaUncontrolled } from "./Uncontrolled";
import Label, { FormLabelProps } from "./Label";
import Control, { FormControlProps } from "./Control";
import Field, { FormFieldProps } from "./Field";
import Icon, { FormIconProps } from "./Icon";
import Error, { FormErrorProps } from "./Error";
import Help from "./Help";
import Select, { SelectOption } from "./Select";
import Textarea from "./Textarea";
import Radio from "./Radio";

export type {
  SelectOption,
  FormProps,
  FormInputProps,
  FormLabelProps,
  FormControlProps,
  FormFieldProps,
  FormIconProps,
  FormErrorProps,
};

export default Object.assign(Form, {
  Label,
  Field,
  Input,
  InputUncontrolled,
  TextareaUncontrolled,
  Control,
  Icon,
  Error,
  Select,
  Textarea,
  Radio,
  Help,
});
