import { useCallback, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  AssessmentGroup,
  patchRemoteAssessmentGroup,
  RemoteOperationState,
  UpdateAssessmentGroupFns,
} from "../../types";
import {
  UpdateAssessmentGroupMutation,
  useGetAssessmentGroupQuery,
  useUpdateAssessmentGroupMutation,
} from "../../graphql/generated";

import assertDefined from "../../utils/assertDefined";
import useUpdateAssessmentGroupHelpers from "../useUpdateAssessmentGroupHelpers";

type UseUpdateAssessmentGroup = {
  assessmentGroup?: AssessmentGroup;
  assessmentGroupState: RemoteOperationState;
  updateAssessmentGroup: UpdateAssessmentGroupFns;
  updateAssessmentGroupState: RemoteOperationState;
  save: () => void;
};

const useUpdateAssessmentGroup = (
  assessmentGroupId: string,
  onNotFound?: () => void
): UseUpdateAssessmentGroup => {
  const { mutate, error, isLoading, reset } = useMutation<
    UpdateAssessmentGroupMutation,
    unknown,
    AssessmentGroup
  >(useUpdateAssessmentGroupMutation.getKey());

  const updateAssessmentGroupState = {
    error: error as Error,
    loading: isLoading,
    reset,
  };

  const getAssessmentGroupState = useGetAssessmentGroupQuery({
    id: assessmentGroupId,
  });

  // Allow action to be taken if we don't get an assessment group
  // really this should be an error from the back end
  // TODO
  useEffect(() => {
    if (
      getAssessmentGroupState.isSuccess &&
      !getAssessmentGroupState.data?.getAssessmentGroup
    ) {
      onNotFound?.();
    }
  }, [
    getAssessmentGroupState.data?.getAssessmentGroup,
    getAssessmentGroupState.isSuccess,
    onNotFound,
  ]);

  const assessmentGroup = getAssessmentGroupState.data?.getAssessmentGroup
    ? patchRemoteAssessmentGroup(
        getAssessmentGroupState.data.getAssessmentGroup
      )
    : undefined;

  const onAssessmentGroupDataChange = useCallback(
    (assessmentGroupData: AssessmentGroup) => {
      mutate({
        ...assessmentGroupData,
        id: assessmentGroupId,
      });
    },
    [assessmentGroupId, mutate]
  );

  const onUpdate = useCallback(
    (fn: (assessmentGroup: AssessmentGroup) => AssessmentGroup) => {
      const updated = fn(assertDefined(assessmentGroup));
      onAssessmentGroupDataChange(updated);
    },
    [assessmentGroup, onAssessmentGroupDataChange]
  );

  const updateAssessmentGroup = useUpdateAssessmentGroupHelpers(onUpdate);

  const save = useCallback(
    () => onAssessmentGroupDataChange(assertDefined(assessmentGroup)),
    [onAssessmentGroupDataChange, assessmentGroup]
  );

  return {
    assessmentGroup,
    assessmentGroupState: {
      loading:
        getAssessmentGroupState.isLoading && getAssessmentGroupState.isFetching,
      paused: getAssessmentGroupState.isPaused,
      error: getAssessmentGroupState.error as Error,
    },
    updateAssessmentGroup,
    updateAssessmentGroupState: {
      error: updateAssessmentGroupState.error,
      loading: updateAssessmentGroupState.loading,
      reset: updateAssessmentGroupState.reset,
    },
    save,
  };
};

export default useUpdateAssessmentGroup;
