import { useCallback } from "react";

import {
  AssessmentScore,
  Assessment,
  Patient,
  Recommendation,
  SetAssessmentDataFn,
  Sketch,
  UpdateAssessmentFns,
} from "../types";

type SetAssessmentFn = (fn: (assessment: Assessment) => Assessment) => void;

const useUpdateAssessmentHelpers = (
  setAssessment: SetAssessmentFn
): UpdateAssessmentFns => {
  const setPartialSelectedAssessment = useCallback(
    (values: (currentState: Assessment) => Partial<Assessment>) =>
      setAssessment((currentState) => ({
        ...currentState,
        ...values(currentState),
      })),
    [setAssessment]
  );

  const setAssessmentData: SetAssessmentDataFn = useCallback(
    (item: AssessmentScore) =>
      setPartialSelectedAssessment(({ assessmentScores }) => ({
        assessmentScores: [
          ...assessmentScores.filter(
            (d) => d.main !== item.main || d.sub !== item.sub
          ),
          item,
        ],
      })),
    [setPartialSelectedAssessment]
  );

  const setSketchData = useCallback(
    (sketch: Sketch) =>
      setPartialSelectedAssessment(({ sketches }) => ({
        sketches: [...sketches.filter((d) => d.id !== sketch.id), sketch],
      })),
    [setPartialSelectedAssessment]
  );

  const setPatientData = useCallback(
    (newPatient: Partial<Patient>) =>
      setPartialSelectedAssessment(({ patient }) => ({
        patient: { ...patient, ...newPatient },
      })),
    [setPartialSelectedAssessment]
  );

  const setEquipmentPrescriptionData = useCallback(
    (equipmentPrescription: string) =>
      setPartialSelectedAssessment(() => ({ equipmentPrescription })),
    [setPartialSelectedAssessment]
  );

  const setRecommendationData = useCallback(
    (recommendations: Recommendation[]) =>
      setPartialSelectedAssessment(() => ({ recommendations })),
    [setPartialSelectedAssessment]
  );

  return {
    setAssessmentData,
    setSketchData,
    setPatientData,
    setEquipmentPrescriptionData,
    setRecommendationData,
  };
};

export default useUpdateAssessmentHelpers;
