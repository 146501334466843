import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icons } from "../../constants";
import { Icon } from "../../library";
import { ClassNameProp } from "../../library/utils";

type Props = {
  id: string;
} & ClassNameProp;

const BackToAssessmentsLink: React.FC<Props> = ({ id, className }) => (
  <Link
    className={classNames(className, "button is-primary")}
    to={`/assessments?group=${id}`}
  >
    <Icon icon={Icons.exit} />
    <span>Save and exit</span>
  </Link>
);

export default BackToAssessmentsLink;
