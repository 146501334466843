import React from "react";
import { Button, Modal, useModal } from "../../library";
import HelpContent from "./HelpContent";

const HelpModal: React.FC = () => {
  const { isModalOpen, closeModal, openModal } = useModal();

  return (
    <>
      <Button onClick={openModal} className="has-text-white is-ghost mx-4">
        <Button.Icon icon="question-circle" />
        <span>What is the CABA assessment tool?</span>
      </Button>

      <Modal isOpen={isModalOpen} portal onClose={closeModal}>
        <Modal.Header>What is the CABA assessment tool?</Modal.Header>
        <Modal.Body className="m-0">
          <HelpContent />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HelpModal;
