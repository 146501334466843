import React from "react";

import { Authenticator } from "@aws-amplify/ui-react";

import { Dark } from "../../components/Layouts";
import { Navigate } from "react-router-dom";

const AuthenticatorScreen: React.FC = () => {
  return (
    <Dark.Container>
      <Dark.Content>
        <Authenticator loginMechanisms={["email"]}>
          <Navigate to="/" />
        </Authenticator>
      </Dark.Content>
    </Dark.Container>
  );
};

export default AuthenticatorScreen;
