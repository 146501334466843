import classNames from "classnames";
import { PropsWithChildren, useCallback, useRef, useState } from "react";
import useClickOutside from "../hooks/useClickOutside";
import htmlElementComponent from "../htmlElementComponent";
import { ClassNameProp } from "../utils";

const Item = htmlElementComponent({
  as: "a",
  classes: "dropdown-item",
});

const Divider = htmlElementComponent({
  as: "hr",
  classes: "dropdown-divider",
});

type Props = {
  label: React.ReactNode;
} & ClassNameProp;

const Dropdown: React.FC<PropsWithChildren<Props>> = ({
  children,
  label,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useClickOutside(
    useCallback(() => setIsOpen(false), []),
    ref
  );

  return (
    <div
      ref={ref}
      className={classNames(className, "dropdown", {
        "is-active": isOpen,
      })}
    >
      <div className="dropdown-trigger">
        <button
          className="button is-ghost"
          aria-haspopup="true"
          aria-controls="dropdown-menu"
          onClick={() => setIsOpen((o) => !o)}
        >
          {label}
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  );
};

export default Object.assign(Dropdown, { Item, Divider });
