import React from "react";

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import { AssessmentResult } from "../../types";
import colors from "./colors";

type Props = {
  assessmentResults: AssessmentResult[];
};

const resultsStyles = StyleSheet.create({
  table: {
    fontSize: 7,
    width: 300,
  },
  tableRow: {
    display: "flex",
    flexDirection: "row",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
  },
  lastRow: {
    borderTop: `0.5 solid ${colors.lightGrey}`,
  },
  tableTitleRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "extrabold",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lightGrey,
  },
  tableHeaderRow: {
    display: "flex",
    flexDirection: "row",
    fontWeight: "extrabold",
    borderTop: `0.5 solid ${colors.lightGrey}`,
    borderRight: `0.5 solid ${colors.lightGrey}`,
    backgroundColor: colors.lighterGrey,
  },
  labelColumn: {
    width: 140,
    padding: 3,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
  valueColumn: {
    padding: 2,
    width: 70,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
  directionColumn: {
    padding: 3,
    borderLeft: `0.5 solid ${colors.lightGrey}`,
  },
  tag: {
    padding: 1,
    backgroundColor: colors.lightGrey,
    borderRadius: 3,
    color: colors.white,
    textAlign: "center",
  },
  tag0: {
    padding: 1,
    backgroundColor: colors.green,
    borderRadius: 3,
    color: colors.white,
    textAlign: "center",
  },
  tag1: {
    padding: 1,
    backgroundColor: colors.yellow,
    borderRadius: 3,
    color: colors.black,
    textAlign: "center",
  },
  tag2: {
    padding: 1,
    backgroundColor: colors.orange,
    borderRadius: 3,
    color: colors.white,
    textAlign: "center",
  },
  tag3: {
    padding: 1,
    backgroundColor: colors.red,
    borderRadius: 3,
    color: colors.white,
    textAlign: "center",
  },
});

const scoreToColour = [
  resultsStyles.tag0,
  resultsStyles.tag1,
  resultsStyles.tag2,
  resultsStyles.tag3,
];

const getTagColorForScore = (score: number) => scoreToColour[score] || "";

const AssessmentResultsPdf: React.FC<Props> = ({ assessmentResults }) => {
  const rows = assessmentResults
    .map((a) => {
      return [
        <View style={resultsStyles.tableHeaderRow} key={`${a.name}`}>
          <Text style={resultsStyles.labelColumn}>{a.name}</Text>
          <View style={resultsStyles.valueColumn}>
            <Text style={resultsStyles.tag}>Total: {a.score}</Text>
          </View>
          <Text style={resultsStyles.directionColumn}></Text>
        </View>,

        ...a.criteria.map((c) => (
          <View style={resultsStyles.tableRow} key={`${a.name}-${c.name}`}>
            <Text style={resultsStyles.labelColumn}>{c.name}</Text>
            <View style={resultsStyles.valueColumn}>
              {c.data && (
                <Text style={getTagColorForScore(c.data.value)}>
                  {c.data.value}
                </Text>
              )}
            </View>
            <Text style={resultsStyles.directionColumn}>
              {c.data?.direction}
            </Text>
          </View>
        )),
        <View style={resultsStyles.tableRow} key={`${a.name}-spacer`}>
          <Text style={resultsStyles.labelColumn}> </Text>
        </View>,
      ];
    })
    .flat();

  rows.pop(); // remove the last spacer row
  rows.push(<View style={resultsStyles.lastRow} key="last"></View>); // add an empty row with a border top to finish the table

  return (
    <View style={resultsStyles.table}>
      <View style={resultsStyles.tableTitleRow}>
        <Text style={resultsStyles.labelColumn}>Section</Text>
        <Text style={resultsStyles.valueColumn}>Score</Text>
        <Text style={resultsStyles.directionColumn}>Movement direction</Text>
      </View>
      {rows}
    </View>
  );
};

export default AssessmentResultsPdf;
