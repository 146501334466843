import React from "react";
import { useFormValidation } from "../../hooks";
import { Form } from "../../library";
import {
  AssessmentGroup,
  AssessmentGroupDetails,
  SetAssessmentGroupDetailsFn,
} from "../../types";
import FormValidationError from "../FormValidationError";

type Props = {
  updateAssessmentGroup: SetAssessmentGroupDetailsFn;
  assessmentGroup: AssessmentGroup;
};

const AssessmentGroupDetailsForm: React.FC<Props> = ({
  updateAssessmentGroup,
  assessmentGroup,
}) => {
  const { register, errors, handleBlur } =
    useFormValidation<AssessmentGroupDetails>({
      values: assessmentGroup,
    });

  return (
    <Form onBlur={handleBlur(updateAssessmentGroup)}>
      <Form.Field>
        <Form.Label>Patient name</Form.Label>
        <Form.InputUncontrolled
          {...register("patient", {
            required: true,
            maxLength: 150,
          })}
        />
        <FormValidationError field="Patient name" error={errors.patient} />
      </Form.Field>

      <Form.Field>
        <Form.Label>Assessment description</Form.Label>
        <Form.InputUncontrolled
          {...register("description", {
            required: true,
            maxLength: 150,
          })}
        />
        <FormValidationError
          field="Assessment description"
          error={errors.description}
        />
      </Form.Field>

      <Form.Field>
        <Form.Label>Therapist</Form.Label>
        <Form.InputUncontrolled
          {...register("therapist", {
            maxLength: 150,
          })}
        />
        <FormValidationError field="Therapist" error={errors.therapist} />
      </Form.Field>

      <Form.Field>
        <Form.Label>Location</Form.Label>
        <Form.InputUncontrolled
          {...register("location", {
            maxLength: 150,
          })}
        />
        <FormValidationError field="Location" error={errors.location} />
      </Form.Field>
    </Form>
  );
};

export default AssessmentGroupDetailsForm;
