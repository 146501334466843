import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { default as AssessmentComponent } from "../../components/Assessment";
import { useUpdateAssessment } from "../../hooks";
import { useCreateAssessmentWithGroupId } from "../../hooks/graphql/useCreateAssessment";
import Loading from "../../components/Loading";
import { Slate } from "../../library";

const AssessmentScreen: React.FC = () => {
  const { id } = useParams();

  if (!id) throw Error("Route parameter 'id' is undefined");

  const navigate = useNavigate();

  const {
    selectedAssessment,
    selectedAssessmentState,
    updateSelectedAssessment,
    updateSelectedAssessmentState,
    save,
  } = useUpdateAssessment(id);

  const { createAssessment } = useCreateAssessmentWithGroupId(
    selectedAssessment?.assessmentGroupId || ""
  );

  if (selectedAssessmentState.error)
    return (
      <Slate.Error>An error occurred when loading your assessment</Slate.Error>
    );

  if (selectedAssessmentState.loading || !selectedAssessment)
    return <Loading />;

  return (
    <AssessmentComponent
      hasRealAssessmentGroup
      retry={updateSelectedAssessmentState.error ? save : undefined}
      clearSelectedAssessment={() => navigate("/")}
      createAssessment={createAssessment}
      selectedAssessment={selectedAssessment}
      updateSelectedAssessment={updateSelectedAssessment}
    />
  );
};

export default AssessmentScreen;
