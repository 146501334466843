import { QueryClient } from "@tanstack/react-query";
import { Assessment } from "../../types";

import {
  useCreateAssessmentMutation,
  useGetAssessmentQuery,
} from "../generated";

const setDefaultCreateAssessmentMutation = (queryClient: QueryClient) => {
  queryClient.setMutationDefaults(useCreateAssessmentMutation.getKey(), {
    mutationFn: (data: Assessment) =>
      useCreateAssessmentMutation.fetcher(data, {})(),
    onMutate: async (getAssessment: Assessment) => {
      const queryKey = useGetAssessmentQuery.getKey({ id: getAssessment.id });

      // Cancel current queries
      await queryClient.cancelQueries({ queryKey });

      // Add optimistic assessment
      queryClient.setQueryData(queryKey, { getAssessment });
    },
    onError: (_error, variables: Assessment) => {
      const queryKey = useGetAssessmentQuery.getKey({ id: variables.id });
      queryClient.setQueryData(queryKey, undefined);
    },
    onSettled: (_data, _error, variables: Assessment) => {
      const queryKey = useGetAssessmentQuery.getKey({ id: variables.id });
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export default setDefaultCreateAssessmentMutation;
