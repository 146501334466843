import { useCallback } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  Assessment,
  patchRemoteAssessment,
  RemoteOperationState,
  UpdateAssessmentFns,
} from "../../types";
import {
  UpdateAssessmentMutation,
  useGetAssessmentQuery,
  useUpdateAssessmentMutation,
} from "../../graphql/generated";
import useUpdateAssessmentHelpers from "../useUpdateAssessmentHelpers";
import assertDefined from "../../utils/assertDefined";

type UseUpdateAssessment = {
  selectedAssessment?: Assessment;
  selectedAssessmentState: RemoteOperationState;
  updateSelectedAssessment: UpdateAssessmentFns;
  updateSelectedAssessmentState: RemoteOperationState;
  save: () => void;
};

const useUpdateAssessment = (assessmentId: string): UseUpdateAssessment => {
  const { mutate, error, isLoading, reset } = useMutation<
    UpdateAssessmentMutation,
    unknown,
    Assessment
  >(useUpdateAssessmentMutation.getKey());

  const updateAssessmentState = {
    error: error as Error,
    loading: isLoading,
    reset,
  };

  const getAssessmentState = useGetAssessmentQuery({
    id: assessmentId,
  });

  // TODO - handle this properly with a 404 message rather than error boundary
  if (getAssessmentState.isSuccess && !getAssessmentState.data?.getAssessment) {
    throw new Error(`Assessment ${assessmentId} not found`);
  }

  const selectedAssessment = getAssessmentState.data?.getAssessment
    ? patchRemoteAssessment(getAssessmentState.data.getAssessment)
    : undefined;

  const onAssessmentDataChange = useCallback(
    (assessmentData: Assessment) => {
      mutate({
        ...assessmentData,
        id: assessmentId,
      });
    },
    [assessmentId, mutate]
  );

  const onUpdate = useCallback(
    (fn: (assessment: Assessment) => Assessment) => {
      const updated = fn(assertDefined(selectedAssessment));
      onAssessmentDataChange(updated);
    },
    [selectedAssessment, onAssessmentDataChange]
  );

  const updateSelectedAssessment = useUpdateAssessmentHelpers(onUpdate);

  const save = useCallback(
    () => onAssessmentDataChange(assertDefined(selectedAssessment)),
    [onAssessmentDataChange, selectedAssessment]
  );

  return {
    selectedAssessment,
    selectedAssessmentState: {
      error: getAssessmentState.error as Error,
      loading: getAssessmentState.isLoading,
    },
    updateSelectedAssessment,
    updateSelectedAssessmentState: {
      error: updateAssessmentState.error,
      loading: updateAssessmentState.loading,
      reset: updateAssessmentState.reset,
    },
    save,
  };
};

export default useUpdateAssessment;
