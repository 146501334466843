import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
} from "@tanstack/react-query";
import { amplifyFetcher } from "./amplify-fetcher";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Assessment = {
  __typename?: "Assessment";
  assessmentCriterion: AssessmentCriterion;
  assessmentGroupId: Scalars["String"];
  assessmentScores?: Maybe<Array<AssessmentScore>>;
  createdAt: Scalars["String"];
  equipmentPrescription?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  patient?: Maybe<Patient>;
  recommendations?: Maybe<Array<Recommendation>>;
  sketches?: Maybe<Array<Sketch>>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export enum AssessmentCriteria {
  Lying = "Lying",
  Sitting = "Sitting",
  Standing = "Standing",
}

export type AssessmentCriterion = {
  __typename?: "AssessmentCriterion";
  criterion: AssessmentCriteria;
  details: Scalars["String"];
};

export type AssessmentCriterionInput = {
  criterion: AssessmentCriteria;
  details: Scalars["String"];
};

export type AssessmentGroup = {
  __typename?: "AssessmentGroup";
  assessmentDate: Scalars["String"];
  assessments?: Maybe<Array<Assessment>>;
  createdAt: Scalars["String"];
  description: Scalars["String"];
  equipmentPrescription?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  location?: Maybe<Scalars["String"]>;
  notes?: Maybe<Scalars["String"]>;
  patient: Scalars["String"];
  recommendations?: Maybe<Array<Recommendation>>;
  therapist?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
};

export type AssessmentScore = {
  __typename?: "AssessmentScore";
  data: AssessmentScoreValue;
  main: Scalars["String"];
  sub: Scalars["String"];
};

export type AssessmentScoreInput = {
  data: AssessmentScoreValueInput;
  main: Scalars["String"];
  sub: Scalars["String"];
};

export type AssessmentScoreValue = {
  __typename?: "AssessmentScoreValue";
  direction: Scalars["String"];
  value: Scalars["Int"];
};

export type AssessmentScoreValueInput = {
  direction: Scalars["String"];
  value: Scalars["Int"];
};

export type Mutation = {
  __typename?: "Mutation";
  createAssessment?: Maybe<Assessment>;
  createAssessmentGroup?: Maybe<AssessmentGroup>;
  deleteAssessment?: Maybe<Scalars["Boolean"]>;
  deleteAssessmentGroup?: Maybe<Scalars["Boolean"]>;
  updateAssessment?: Maybe<Assessment>;
  updateAssessmentGroup?: Maybe<AssessmentGroup>;
};

export type MutationCreateAssessmentArgs = {
  assessmentCriterion: AssessmentCriterionInput;
  assessmentGroupId: Scalars["String"];
  assessmentScores?: InputMaybe<Array<AssessmentScoreInput>>;
  equipmentPrescription?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  patient?: InputMaybe<PatientInput>;
  recommendations?: InputMaybe<Array<RecommendationInput>>;
  sketches?: InputMaybe<Array<SketchInput>>;
};

export type MutationCreateAssessmentGroupArgs = {
  assessmentDate: Scalars["String"];
  description: Scalars["String"];
  id: Scalars["ID"];
  patient: Scalars["String"];
};

export type MutationDeleteAssessmentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteAssessmentGroupArgs = {
  id: Scalars["ID"];
};

export type MutationUpdateAssessmentArgs = {
  assessmentScores: Array<AssessmentScoreInput>;
  equipmentPrescription: Scalars["String"];
  id: Scalars["ID"];
  patient: PatientInput;
  recommendations: Array<RecommendationInput>;
  sketches: Array<SketchInput>;
};

export type MutationUpdateAssessmentGroupArgs = {
  description: Scalars["String"];
  equipmentPrescription: Scalars["String"];
  id: Scalars["ID"];
  location: Scalars["String"];
  notes: Scalars["String"];
  patient: Scalars["String"];
  recommendations: Array<RecommendationInput>;
  therapist: Scalars["String"];
};

export type PaginatedAssessmentGroups = {
  __typename?: "PaginatedAssessmentGroups";
  items: Array<AssessmentGroup>;
  nextToken?: Maybe<Scalars["String"]>;
};

export type Patient = {
  __typename?: "Patient";
  date: Scalars["String"];
  location: Scalars["String"];
  name: Scalars["String"];
  notes: Scalars["String"];
  therapist: Scalars["String"];
};

export type PatientInput = {
  date: Scalars["String"];
  location: Scalars["String"];
  name: Scalars["String"];
  notes: Scalars["String"];
  therapist: Scalars["String"];
};

export type Query = {
  __typename?: "Query";
  getAssessment?: Maybe<Assessment>;
  getAssessmentGroup?: Maybe<AssessmentGroup>;
  getAssessmentGroups: PaginatedAssessmentGroups;
  getAssessments: Array<Assessment>;
};

export type QueryGetAssessmentArgs = {
  id: Scalars["ID"];
};

export type QueryGetAssessmentGroupArgs = {
  id: Scalars["ID"];
};

export type QueryGetAssessmentGroupsArgs = {
  limit: Scalars["Int"];
  nextToken?: InputMaybe<Scalars["String"]>;
  search?: InputMaybe<Scalars["String"]>;
};

export type QueryGetAssessmentsArgs = {
  assessmentGroupId?: InputMaybe<Scalars["String"]>;
};

export type Recommendation = {
  __typename?: "Recommendation";
  area: Scalars["String"];
  problem: Scalars["String"];
  recommendation: Scalars["String"];
};

export type RecommendationInput = {
  area: Scalars["String"];
  problem: Scalars["String"];
  recommendation: Scalars["String"];
};

export type Sketch = {
  __typename?: "Sketch";
  data: Scalars["String"];
  id: Scalars["String"];
  image: Scalars["String"];
};

export type SketchInput = {
  data: Scalars["String"];
  id: Scalars["String"];
  image: Scalars["String"];
};

export type AssessmentCommonFieldsFragment = {
  __typename?: "Assessment";
  id: string;
  createdAt: string;
  updatedAt?: string | null;
  assessmentGroupId: string;
  assessmentCriterion: {
    __typename?: "AssessmentCriterion";
    criterion: AssessmentCriteria;
    details: string;
  };
  patient?: {
    __typename?: "Patient";
    name: string;
    therapist: string;
    location: string;
    date: string;
    notes: string;
  } | null;
};

export type AssessmentExtendedFieldsFragment = {
  __typename?: "Assessment";
  equipmentPrescription?: string | null;
  assessmentScores?: Array<{
    __typename?: "AssessmentScore";
    main: string;
    sub: string;
    data: {
      __typename?: "AssessmentScoreValue";
      direction: string;
      value: number;
    };
  }> | null;
  sketches?: Array<{
    __typename?: "Sketch";
    id: string;
    image: string;
    data: string;
  }> | null;
  recommendations?: Array<{
    __typename?: "Recommendation";
    area: string;
    problem: string;
    recommendation: string;
  }> | null;
};

export type AssessmentGroupCommonFieldsFragment = {
  __typename?: "AssessmentGroup";
  id: string;
  createdAt: string;
  updatedAt?: string | null;
  assessmentDate: string;
  description: string;
  patient: string;
  notes?: string | null;
  location?: string | null;
  therapist?: string | null;
  equipmentPrescription?: string | null;
  recommendations?: Array<{
    __typename?: "Recommendation";
    area: string;
    problem: string;
    recommendation: string;
  }> | null;
};

export type GetAssessmentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssessmentQuery = {
  __typename?: "Query";
  getAssessment?: {
    __typename?: "Assessment";
    id: string;
    createdAt: string;
    updatedAt?: string | null;
    assessmentGroupId: string;
    equipmentPrescription?: string | null;
    assessmentCriterion: {
      __typename?: "AssessmentCriterion";
      criterion: AssessmentCriteria;
      details: string;
    };
    patient?: {
      __typename?: "Patient";
      name: string;
      therapist: string;
      location: string;
      date: string;
      notes: string;
    } | null;
    assessmentScores?: Array<{
      __typename?: "AssessmentScore";
      main: string;
      sub: string;
      data: {
        __typename?: "AssessmentScoreValue";
        direction: string;
        value: number;
      };
    }> | null;
    sketches?: Array<{
      __typename?: "Sketch";
      id: string;
      image: string;
      data: string;
    }> | null;
    recommendations?: Array<{
      __typename?: "Recommendation";
      area: string;
      problem: string;
      recommendation: string;
    }> | null;
  } | null;
};

export type UpdateAssessmentGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  patient: Scalars["String"];
  location: Scalars["String"];
  therapist: Scalars["String"];
  description: Scalars["String"];
  notes: Scalars["String"];
  recommendations: Array<RecommendationInput> | RecommendationInput;
  equipmentPrescription: Scalars["String"];
}>;

export type UpdateAssessmentGroupMutation = {
  __typename?: "Mutation";
  updateAssessmentGroup?: {
    __typename?: "AssessmentGroup";
    id: string;
    createdAt: string;
    updatedAt?: string | null;
    assessmentDate: string;
    description: string;
    patient: string;
    notes?: string | null;
    location?: string | null;
    therapist?: string | null;
    equipmentPrescription?: string | null;
    recommendations?: Array<{
      __typename?: "Recommendation";
      area: string;
      problem: string;
      recommendation: string;
    }> | null;
  } | null;
};

export type GetAssessmentGroupsQueryVariables = Exact<{
  limit: Scalars["Int"];
  search?: InputMaybe<Scalars["String"]>;
  nextToken?: InputMaybe<Scalars["String"]>;
}>;

export type GetAssessmentGroupsQuery = {
  __typename?: "Query";
  getAssessmentGroups: {
    __typename?: "PaginatedAssessmentGroups";
    nextToken?: string | null;
    items: Array<{
      __typename?: "AssessmentGroup";
      id: string;
      createdAt: string;
      updatedAt?: string | null;
      assessmentDate: string;
      description: string;
      patient: string;
      notes?: string | null;
      location?: string | null;
      therapist?: string | null;
      equipmentPrescription?: string | null;
      recommendations?: Array<{
        __typename?: "Recommendation";
        area: string;
        problem: string;
        recommendation: string;
      }> | null;
    }>;
  };
};

export type GetAssessmentGroupQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GetAssessmentGroupQuery = {
  __typename?: "Query";
  getAssessmentGroup?: {
    __typename?: "AssessmentGroup";
    id: string;
    createdAt: string;
    updatedAt?: string | null;
    assessmentDate: string;
    description: string;
    patient: string;
    notes?: string | null;
    location?: string | null;
    therapist?: string | null;
    equipmentPrescription?: string | null;
    assessments?: Array<{
      __typename?: "Assessment";
      id: string;
      createdAt: string;
      updatedAt?: string | null;
      assessmentGroupId: string;
      equipmentPrescription?: string | null;
      assessmentCriterion: {
        __typename?: "AssessmentCriterion";
        criterion: AssessmentCriteria;
        details: string;
      };
      patient?: {
        __typename?: "Patient";
        name: string;
        therapist: string;
        location: string;
        date: string;
        notes: string;
      } | null;
      assessmentScores?: Array<{
        __typename?: "AssessmentScore";
        main: string;
        sub: string;
        data: {
          __typename?: "AssessmentScoreValue";
          direction: string;
          value: number;
        };
      }> | null;
      sketches?: Array<{
        __typename?: "Sketch";
        id: string;
        image: string;
        data: string;
      }> | null;
      recommendations?: Array<{
        __typename?: "Recommendation";
        area: string;
        problem: string;
        recommendation: string;
      }> | null;
    }> | null;
    recommendations?: Array<{
      __typename?: "Recommendation";
      area: string;
      problem: string;
      recommendation: string;
    }> | null;
  } | null;
};

export type CreateAssessmentGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  patient: Scalars["String"];
  description: Scalars["String"];
  assessmentDate: Scalars["String"];
}>;

export type CreateAssessmentGroupMutation = {
  __typename?: "Mutation";
  createAssessmentGroup?: { __typename?: "AssessmentGroup"; id: string } | null;
};

export type CreateAssessmentMutationVariables = Exact<{
  id: Scalars["ID"];
  assessmentGroupId: Scalars["String"];
  assessmentCriterion: AssessmentCriterionInput;
  assessmentScores?: InputMaybe<
    Array<AssessmentScoreInput> | AssessmentScoreInput
  >;
  patient?: InputMaybe<PatientInput>;
  sketches?: InputMaybe<Array<SketchInput> | SketchInput>;
  recommendations?: InputMaybe<
    Array<RecommendationInput> | RecommendationInput
  >;
  equipmentPrescription?: InputMaybe<Scalars["String"]>;
}>;

export type CreateAssessmentMutation = {
  __typename?: "Mutation";
  createAssessment?: { __typename?: "Assessment"; id: string } | null;
};

export type UpdateAssessmentMutationVariables = Exact<{
  id: Scalars["ID"];
  assessmentScores: Array<AssessmentScoreInput> | AssessmentScoreInput;
  patient: PatientInput;
  sketches: Array<SketchInput> | SketchInput;
  recommendations: Array<RecommendationInput> | RecommendationInput;
  equipmentPrescription: Scalars["String"];
}>;

export type UpdateAssessmentMutation = {
  __typename?: "Mutation";
  updateAssessment?: { __typename?: "Assessment"; id: string } | null;
};

export type DeleteAssessmentGroupMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAssessmentGroupMutation = {
  __typename?: "Mutation";
  deleteAssessmentGroup?: boolean | null;
};

export type DeleteAssessmentMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteAssessmentMutation = {
  __typename?: "Mutation";
  deleteAssessment?: boolean | null;
};

export const AssessmentCommonFieldsFragmentDoc = `
    fragment AssessmentCommonFields on Assessment {
  id
  createdAt
  updatedAt
  assessmentGroupId
  assessmentCriterion {
    criterion
    details
  }
  patient {
    name
    therapist
    location
    date
    notes
  }
}
    `;
export const AssessmentExtendedFieldsFragmentDoc = `
    fragment AssessmentExtendedFields on Assessment {
  assessmentScores {
    main
    sub
    data {
      direction
      value
    }
  }
  sketches {
    id
    image
    data
  }
  recommendations {
    area
    problem
    recommendation
  }
  equipmentPrescription
}
    `;
export const AssessmentGroupCommonFieldsFragmentDoc = `
    fragment AssessmentGroupCommonFields on AssessmentGroup {
  id
  createdAt
  updatedAt
  assessmentDate
  description
  patient
  notes
  location
  therapist
  recommendations {
    area
    problem
    recommendation
  }
  equipmentPrescription
}
    `;
export const GetAssessmentDocument = `
    query GetAssessment($id: ID!) {
  getAssessment(id: $id) {
    ...AssessmentCommonFields
    ...AssessmentExtendedFields
  }
}
    ${AssessmentCommonFieldsFragmentDoc}
${AssessmentExtendedFieldsFragmentDoc}`;
export const useGetAssessmentQuery = <
  TData = GetAssessmentQuery,
  TError = unknown
>(
  variables: GetAssessmentQueryVariables,
  options?: UseQueryOptions<GetAssessmentQuery, TError, TData>
) =>
  useQuery<GetAssessmentQuery, TError, TData>(
    ["GetAssessment", variables],
    amplifyFetcher<GetAssessmentQuery, GetAssessmentQueryVariables>(
      GetAssessmentDocument,
      variables
    ),
    options
  );

useGetAssessmentQuery.getKey = (variables: GetAssessmentQueryVariables) => [
  "GetAssessment",
  variables,
];
export const useInfiniteGetAssessmentQuery = <
  TData = GetAssessmentQuery,
  TError = unknown
>(
  pageParamKey: keyof GetAssessmentQueryVariables,
  variables: GetAssessmentQueryVariables,
  options?: UseInfiniteQueryOptions<GetAssessmentQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAssessmentQuery, TError, TData>(
    ["GetAssessment.infinite", variables],
    (metaData) =>
      amplifyFetcher<GetAssessmentQuery, GetAssessmentQueryVariables>(
        GetAssessmentDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteGetAssessmentQuery.getKey = (
  variables: GetAssessmentQueryVariables
) => ["GetAssessment.infinite", variables];
useGetAssessmentQuery.fetcher = (
  variables: GetAssessmentQueryVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<GetAssessmentQuery, GetAssessmentQueryVariables>(
    GetAssessmentDocument,
    variables,
    options
  );
export const UpdateAssessmentGroupDocument = `
    mutation UpdateAssessmentGroup($id: ID!, $patient: String!, $location: String!, $therapist: String!, $description: String!, $notes: String!, $recommendations: [RecommendationInput!]!, $equipmentPrescription: String!) {
  updateAssessmentGroup(
    id: $id
    notes: $notes
    patient: $patient
    location: $location
    therapist: $therapist
    description: $description
    recommendations: $recommendations
    equipmentPrescription: $equipmentPrescription
  ) {
    ...AssessmentGroupCommonFields
  }
}
    ${AssessmentGroupCommonFieldsFragmentDoc}`;
export const useUpdateAssessmentGroupMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateAssessmentGroupMutation,
    TError,
    UpdateAssessmentGroupMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateAssessmentGroupMutation,
    TError,
    UpdateAssessmentGroupMutationVariables,
    TContext
  >(
    ["UpdateAssessmentGroup"],
    (variables?: UpdateAssessmentGroupMutationVariables) =>
      amplifyFetcher<
        UpdateAssessmentGroupMutation,
        UpdateAssessmentGroupMutationVariables
      >(UpdateAssessmentGroupDocument, variables)(),
    options
  );
useUpdateAssessmentGroupMutation.getKey = () => ["UpdateAssessmentGroup"];

useUpdateAssessmentGroupMutation.fetcher = (
  variables: UpdateAssessmentGroupMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<
    UpdateAssessmentGroupMutation,
    UpdateAssessmentGroupMutationVariables
  >(UpdateAssessmentGroupDocument, variables, options);
export const GetAssessmentGroupsDocument = `
    query GetAssessmentGroups($limit: Int!, $search: String, $nextToken: String) {
  getAssessmentGroups(limit: $limit, search: $search, nextToken: $nextToken) {
    nextToken
    items {
      ...AssessmentGroupCommonFields
    }
  }
}
    ${AssessmentGroupCommonFieldsFragmentDoc}`;
export const useGetAssessmentGroupsQuery = <
  TData = GetAssessmentGroupsQuery,
  TError = unknown
>(
  variables: GetAssessmentGroupsQueryVariables,
  options?: UseQueryOptions<GetAssessmentGroupsQuery, TError, TData>
) =>
  useQuery<GetAssessmentGroupsQuery, TError, TData>(
    ["GetAssessmentGroups", variables],
    amplifyFetcher<GetAssessmentGroupsQuery, GetAssessmentGroupsQueryVariables>(
      GetAssessmentGroupsDocument,
      variables
    ),
    options
  );

useGetAssessmentGroupsQuery.getKey = (
  variables: GetAssessmentGroupsQueryVariables
) => ["GetAssessmentGroups", variables];
export const useInfiniteGetAssessmentGroupsQuery = <
  TData = GetAssessmentGroupsQuery,
  TError = unknown
>(
  pageParamKey: keyof GetAssessmentGroupsQueryVariables,
  variables: GetAssessmentGroupsQueryVariables,
  options?: UseInfiniteQueryOptions<GetAssessmentGroupsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAssessmentGroupsQuery, TError, TData>(
    ["GetAssessmentGroups.infinite", variables],
    (metaData) =>
      amplifyFetcher<
        GetAssessmentGroupsQuery,
        GetAssessmentGroupsQueryVariables
      >(GetAssessmentGroupsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  );
};

useInfiniteGetAssessmentGroupsQuery.getKey = (
  variables: GetAssessmentGroupsQueryVariables
) => ["GetAssessmentGroups.infinite", variables];
useGetAssessmentGroupsQuery.fetcher = (
  variables: GetAssessmentGroupsQueryVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<GetAssessmentGroupsQuery, GetAssessmentGroupsQueryVariables>(
    GetAssessmentGroupsDocument,
    variables,
    options
  );
export const GetAssessmentGroupDocument = `
    query GetAssessmentGroup($id: ID!) {
  getAssessmentGroup(id: $id) {
    ...AssessmentGroupCommonFields
    assessments {
      ...AssessmentCommonFields
      ...AssessmentExtendedFields
    }
  }
}
    ${AssessmentGroupCommonFieldsFragmentDoc}
${AssessmentCommonFieldsFragmentDoc}
${AssessmentExtendedFieldsFragmentDoc}`;
export const useGetAssessmentGroupQuery = <
  TData = GetAssessmentGroupQuery,
  TError = unknown
>(
  variables: GetAssessmentGroupQueryVariables,
  options?: UseQueryOptions<GetAssessmentGroupQuery, TError, TData>
) =>
  useQuery<GetAssessmentGroupQuery, TError, TData>(
    ["GetAssessmentGroup", variables],
    amplifyFetcher<GetAssessmentGroupQuery, GetAssessmentGroupQueryVariables>(
      GetAssessmentGroupDocument,
      variables
    ),
    options
  );

useGetAssessmentGroupQuery.getKey = (
  variables: GetAssessmentGroupQueryVariables
) => ["GetAssessmentGroup", variables];
export const useInfiniteGetAssessmentGroupQuery = <
  TData = GetAssessmentGroupQuery,
  TError = unknown
>(
  pageParamKey: keyof GetAssessmentGroupQueryVariables,
  variables: GetAssessmentGroupQueryVariables,
  options?: UseInfiniteQueryOptions<GetAssessmentGroupQuery, TError, TData>
) => {
  return useInfiniteQuery<GetAssessmentGroupQuery, TError, TData>(
    ["GetAssessmentGroup.infinite", variables],
    (metaData) =>
      amplifyFetcher<GetAssessmentGroupQuery, GetAssessmentGroupQueryVariables>(
        GetAssessmentGroupDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  );
};

useInfiniteGetAssessmentGroupQuery.getKey = (
  variables: GetAssessmentGroupQueryVariables
) => ["GetAssessmentGroup.infinite", variables];
useGetAssessmentGroupQuery.fetcher = (
  variables: GetAssessmentGroupQueryVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<GetAssessmentGroupQuery, GetAssessmentGroupQueryVariables>(
    GetAssessmentGroupDocument,
    variables,
    options
  );
export const CreateAssessmentGroupDocument = `
    mutation CreateAssessmentGroup($id: ID!, $patient: String!, $description: String!, $assessmentDate: String!) {
  createAssessmentGroup(
    id: $id
    patient: $patient
    description: $description
    assessmentDate: $assessmentDate
  ) {
    id
  }
}
    `;
export const useCreateAssessmentGroupMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateAssessmentGroupMutation,
    TError,
    CreateAssessmentGroupMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateAssessmentGroupMutation,
    TError,
    CreateAssessmentGroupMutationVariables,
    TContext
  >(
    ["CreateAssessmentGroup"],
    (variables?: CreateAssessmentGroupMutationVariables) =>
      amplifyFetcher<
        CreateAssessmentGroupMutation,
        CreateAssessmentGroupMutationVariables
      >(CreateAssessmentGroupDocument, variables)(),
    options
  );
useCreateAssessmentGroupMutation.getKey = () => ["CreateAssessmentGroup"];

useCreateAssessmentGroupMutation.fetcher = (
  variables: CreateAssessmentGroupMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<
    CreateAssessmentGroupMutation,
    CreateAssessmentGroupMutationVariables
  >(CreateAssessmentGroupDocument, variables, options);
export const CreateAssessmentDocument = `
    mutation CreateAssessment($id: ID!, $assessmentGroupId: String!, $assessmentCriterion: AssessmentCriterionInput!, $assessmentScores: [AssessmentScoreInput!], $patient: PatientInput, $sketches: [SketchInput!], $recommendations: [RecommendationInput!], $equipmentPrescription: String) {
  createAssessment(
    id: $id
    assessmentGroupId: $assessmentGroupId
    assessmentCriterion: $assessmentCriterion
    assessmentScores: $assessmentScores
    patient: $patient
    sketches: $sketches
    recommendations: $recommendations
    equipmentPrescription: $equipmentPrescription
  ) {
    id
  }
}
    `;
export const useCreateAssessmentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateAssessmentMutation,
    TError,
    CreateAssessmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateAssessmentMutation,
    TError,
    CreateAssessmentMutationVariables,
    TContext
  >(
    ["CreateAssessment"],
    (variables?: CreateAssessmentMutationVariables) =>
      amplifyFetcher<
        CreateAssessmentMutation,
        CreateAssessmentMutationVariables
      >(CreateAssessmentDocument, variables)(),
    options
  );
useCreateAssessmentMutation.getKey = () => ["CreateAssessment"];

useCreateAssessmentMutation.fetcher = (
  variables: CreateAssessmentMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<CreateAssessmentMutation, CreateAssessmentMutationVariables>(
    CreateAssessmentDocument,
    variables,
    options
  );
export const UpdateAssessmentDocument = `
    mutation UpdateAssessment($id: ID!, $assessmentScores: [AssessmentScoreInput!]!, $patient: PatientInput!, $sketches: [SketchInput!]!, $recommendations: [RecommendationInput!]!, $equipmentPrescription: String!) {
  updateAssessment(
    id: $id
    assessmentScores: $assessmentScores
    patient: $patient
    sketches: $sketches
    recommendations: $recommendations
    equipmentPrescription: $equipmentPrescription
  ) {
    id
  }
}
    `;
export const useUpdateAssessmentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateAssessmentMutation,
    TError,
    UpdateAssessmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateAssessmentMutation,
    TError,
    UpdateAssessmentMutationVariables,
    TContext
  >(
    ["UpdateAssessment"],
    (variables?: UpdateAssessmentMutationVariables) =>
      amplifyFetcher<
        UpdateAssessmentMutation,
        UpdateAssessmentMutationVariables
      >(UpdateAssessmentDocument, variables)(),
    options
  );
useUpdateAssessmentMutation.getKey = () => ["UpdateAssessment"];

useUpdateAssessmentMutation.fetcher = (
  variables: UpdateAssessmentMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<UpdateAssessmentMutation, UpdateAssessmentMutationVariables>(
    UpdateAssessmentDocument,
    variables,
    options
  );
export const DeleteAssessmentGroupDocument = `
    mutation DeleteAssessmentGroup($id: ID!) {
  deleteAssessmentGroup(id: $id)
}
    `;
export const useDeleteAssessmentGroupMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteAssessmentGroupMutation,
    TError,
    DeleteAssessmentGroupMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteAssessmentGroupMutation,
    TError,
    DeleteAssessmentGroupMutationVariables,
    TContext
  >(
    ["DeleteAssessmentGroup"],
    (variables?: DeleteAssessmentGroupMutationVariables) =>
      amplifyFetcher<
        DeleteAssessmentGroupMutation,
        DeleteAssessmentGroupMutationVariables
      >(DeleteAssessmentGroupDocument, variables)(),
    options
  );
useDeleteAssessmentGroupMutation.getKey = () => ["DeleteAssessmentGroup"];

useDeleteAssessmentGroupMutation.fetcher = (
  variables: DeleteAssessmentGroupMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<
    DeleteAssessmentGroupMutation,
    DeleteAssessmentGroupMutationVariables
  >(DeleteAssessmentGroupDocument, variables, options);
export const DeleteAssessmentDocument = `
    mutation DeleteAssessment($id: ID!) {
  deleteAssessment(id: $id)
}
    `;
export const useDeleteAssessmentMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteAssessmentMutation,
    TError,
    DeleteAssessmentMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteAssessmentMutation,
    TError,
    DeleteAssessmentMutationVariables,
    TContext
  >(
    ["DeleteAssessment"],
    (variables?: DeleteAssessmentMutationVariables) =>
      amplifyFetcher<
        DeleteAssessmentMutation,
        DeleteAssessmentMutationVariables
      >(DeleteAssessmentDocument, variables)(),
    options
  );
useDeleteAssessmentMutation.getKey = () => ["DeleteAssessment"];

useDeleteAssessmentMutation.fetcher = (
  variables: DeleteAssessmentMutationVariables,
  options?: RequestInit["headers"]
) =>
  amplifyFetcher<DeleteAssessmentMutation, DeleteAssessmentMutationVariables>(
    DeleteAssessmentDocument,
    variables,
    options
  );
