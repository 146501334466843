import { useCallback, useState } from "react";

const useIsLoading = <T extends Array<unknown>, U>(fn: (...args: T) => U) => {
  const [isLoading, setIsLoading] = useState(false);

  const execute = useCallback(
    async (...args: T) => {
      setIsLoading(true);
      try {
        await fn(...args);
      } finally {
        setIsLoading(false);
      }
    },
    [fn]
  );

  return { execute, isLoading };
};

export default useIsLoading;
