import React from "react";

import { Button } from "../../library";
import NewAssessmentModal, {
  useNewAssessmentModal,
} from "../../components/NewAssessmentModal";
import { FriendlyDate } from "../../utils/date";
import {
  AssessmentGroup,
  CreateNewAssessmentFn,
  RemoteOperationState,
} from "../../types";
import Detail from "../../library/detail";
import AssessmentsList from "./AssessmentList";

type AssessmentsGroupDetailProps = {
  assessmentGroup: AssessmentGroup;
  createAssessment: CreateNewAssessmentFn;
  createAssessmentState: RemoteOperationState;
};

const AssessmentGroupDetail: React.FC<AssessmentsGroupDetailProps> = ({
  assessmentGroup,
  createAssessment,
  createAssessmentState,
}) => {
  const { openModal, ...rest } = useNewAssessmentModal(
    createAssessmentState.reset
  );

  return (
    <>
      <Detail label="Patient:">{assessmentGroup.patient}</Detail>
      <Detail label="Assessed:">
        <FriendlyDate date={assessmentGroup.assessmentDate} />
      </Detail>
      <Detail label="Therapist:">{assessmentGroup.therapist}</Detail>
      <Detail label="Location:">{assessmentGroup.location}</Detail>

      <div className="is-flex is-align-items-center">
        <h3 className="pt-4 pb-2">Assessed Criteria</h3>
        <Button
          title="Assess additional criteria"
          className="is-ghost ml-auto"
          onClick={openModal}
        >
          <Button.Icon icon="plus" />
        </Button>
      </div>
      <AssessmentsList
        onStart={openModal}
        assessments={assessmentGroup.assessments}
      />

      <NewAssessmentModal
        createAssessmentState={createAssessmentState}
        createAssessment={createAssessment}
        {...rest}
      />
    </>
  );
};

export default AssessmentGroupDetail;
