import React from "react";
import { RouterProvider } from "react-router-dom";
import { Authenticator } from "@aws-amplify/ui-react";
import router from "./router";
import ReactQuery from "./ReactQuery";

function AuthenticatedApp() {
  return (
    <Authenticator.Provider>
      <ReactQuery>
        <RouterProvider router={router} />
      </ReactQuery>
    </Authenticator.Provider>
  );
}

export default AuthenticatedApp;
