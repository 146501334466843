import React, { PropsWithChildren } from "react";
import htmlElementComponent from "../htmlElementComponent";
import { ClassNameProp } from "../utils";

const Row = htmlElementComponent({
  as: "dl",
  classes: "detail-row is-flex",
});

const Label = htmlElementComponent({
  as: "dd",
  classes: "py-1 has-text-weight-bold",
});

const Value = htmlElementComponent({
  as: "dt",
  classes: "pl-2 p-1",
});

type Props = {
  label: string;
} & ClassNameProp;

const Detail: React.FC<PropsWithChildren<Props>> = ({
  children,
  label,
  className,
}) => (
  <Row className={className}>
    <Label>{label}</Label>
    <Value>{children}</Value>
  </Row>
);

export default Object.assign(Detail, {
  Row,
  Label,
  Value,
});
