import { QueryClient } from "@tanstack/react-query";
import { Assessment } from "../../types";

import {
  useUpdateAssessmentMutation,
  useGetAssessmentQuery,
} from "../generated";
import { removeStaleMutationsFromCache } from "../utils";

const mutationKey = useUpdateAssessmentMutation.getKey();

const setDefaultUpdateAssessment = (queryClient: QueryClient) => {
  queryClient.setMutationDefaults(mutationKey, {
    mutationFn: (assessment: Assessment) =>
      useUpdateAssessmentMutation.fetcher(assessment, {})(),
    onMutate: async (assessment: Assessment) => {
      removeStaleMutationsFromCache(queryClient, mutationKey, assessment.id);

      const queryKey = useGetAssessmentQuery.getKey({ id: assessment.id });

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey });

      // Snapshot the previous value
      const previousData = queryClient.getQueryData(queryKey);

      // Optimistically update to the new value
      queryClient.setQueryData(queryKey, { getAssessment: assessment });

      // Return a context with the data
      return { previousData };
    },
    // If the mutation fails, use the context we returned above
    onError: (_err, assessment: Assessment, context) => {
      const queryKey = useGetAssessmentQuery.getKey({ id: assessment.id });
      queryClient.setQueryData(queryKey, context?.previousData);
    },
    // Always refetch after error or success:
    onSettled: (_data, _error, assessment: Assessment) => {
      const queryKey = useGetAssessmentQuery.getKey({ id: assessment.id });
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export default setDefaultUpdateAssessment;
