import React from "react";

import { Banner, Slate, Menu, Icon, Detail } from "../../library";
import Loading from "../../components/Loading";
import { NavLink, Outlet, useOutletContext, useParams } from "react-router-dom";
import { useUpdateAssessmentGroup } from "../../hooks";
import Resource from "../../components/Layouts/Resource";
import AssessmentRecommendations from "../../components/AssessmentRecommendations";
import AssessmentEquipmentPrescription from "../../components/EquipmentPrescription";
import { mergeRecommendationData } from "../../components/Assessment/utils";
import { AssessmentGroup, UpdateAssessmentGroupFns } from "../../types";
import { Icons } from "../../constants";
import formatAssessmentCriterion from "../../utils/formatAssessmentCriterion";
import { AssessmentGroupDetailsForm } from "../../components/AssessmentGroupDetailsForm";
import BackToAssessmentsLink from "../../components/BackToAssessmentsLink";

type AssessmentGroupScreenContext = {
  assessmentGroup: AssessmentGroup;
  updateAssessmentGroup: UpdateAssessmentGroupFns;
};

const useAssessmentGroupScreenContext = () =>
  useOutletContext<AssessmentGroupScreenContext>();

const AssessmentGroupScreen: React.FC = () => {
  const { id } = useParams();

  if (!id) throw Error("Route parameter 'id' is undefined");

  const {
    assessmentGroup,
    assessmentGroupState,
    updateAssessmentGroup,
    updateAssessmentGroupState,
    save,
  } = useUpdateAssessmentGroup(id);

  if (assessmentGroupState.error)
    return (
      <Slate.Error>An error occurred when loading your assessment</Slate.Error>
    );

  if (assessmentGroupState.loading || !assessmentGroup) return <Loading />;

  const retry = updateAssessmentGroupState.error ? save : undefined;

  return (
    <Resource>
      <Resource.Main>
        <Resource.Header>
          <h1 className="is-truncated is-size-6">
            {assessmentGroup.description}
          </h1>
          <h2 className="is-truncated is-size-5">{assessmentGroup.patient}</h2>
        </Resource.Header>
        {retry && (
          <Banner retry={retry}>
            There was an error saving the assessment
          </Banner>
        )}
        <Resource.Body>
          <Outlet context={{ assessmentGroup, updateAssessmentGroup }} />
        </Resource.Body>
        <Resource.Footer>
          <Detail
            className="is-size-7 is-align-self-center"
            label="Last saved:"
          >
            {assessmentGroup.updatedAt}
          </Detail>
          <BackToAssessmentsLink id={assessmentGroup.id} />
        </Resource.Footer>
      </Resource.Main>
      <Resource.Navigation>
        <div className="is-flex is-flex-grow-1 has-scroll-y">
          <Menu>
            <Menu.List>
              <Menu.Item>
                <NavLink end to="">
                  <Icon icon={Icons.details} />
                  Details
                </NavLink>
              </Menu.Item>
            </Menu.List>
            <Menu.Label>Assessed criteria</Menu.Label>
            <Menu.List>
              {assessmentGroup.assessments.map((a) => (
                <Menu.Item key={a.id}>
                  <NavLink
                    title={a.assessmentCriterion.details}
                    to={`/assessment/${a.id}`}
                  >
                    <Icon icon="minus" />
                    {formatAssessmentCriterion(a.assessmentCriterion)}
                  </NavLink>
                </Menu.Item>
              ))}
            </Menu.List>
            <Menu.Label>Assessment outcomes</Menu.Label>
            <Menu.List>
              <Menu.Item>
                <NavLink to="recommendations">
                  <Icon icon={Icons.recommendations} />
                  Recommendations
                </NavLink>
              </Menu.Item>
              <Menu.Item>
                <NavLink to="equipment">
                  <Icon icon={Icons.equipmentPrescription} />
                  Equipment Prescription
                </NavLink>
              </Menu.Item>
            </Menu.List>
          </Menu>
        </div>
      </Resource.Navigation>
    </Resource>
  );
};

export type AssessmentGroupDetailsFields = Pick<
  AssessmentGroup,
  "patient" | "description" | "notes"
>;

const DetailsScreen: React.FC = () => {
  const { assessmentGroup, updateAssessmentGroup } =
    useAssessmentGroupScreenContext();

  return (
    <AssessmentGroupDetailsForm
      assessmentGroup={assessmentGroup}
      updateAssessmentGroup={updateAssessmentGroup.setDetails}
    />
  );
};

const RecommendationsScreen: React.FC = () => {
  const { assessmentGroup, updateAssessmentGroup } =
    useAssessmentGroupScreenContext();

  return (
    <AssessmentRecommendations
      recommendations={mergeRecommendationData(assessmentGroup.recommendations)}
      updateRecommendations={updateAssessmentGroup.setRecommendationData}
    />
  );
};

const EquipmentPrescriptionScreen: React.FC = () => {
  const { assessmentGroup, updateAssessmentGroup } =
    useAssessmentGroupScreenContext();

  return (
    <AssessmentEquipmentPrescription
      equipmentPrescription={assessmentGroup.equipmentPrescription}
      setEquipmentPrescription={
        updateAssessmentGroup.setEquipmentPrescriptionData
      }
    />
  );
};

export default Object.assign(AssessmentGroupScreen, {
  Recommendations: RecommendationsScreen,
  EquipmentPrescription: EquipmentPrescriptionScreen,
  Details: DetailsScreen,
});
