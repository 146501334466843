declare global {
  interface Window {
    enableFeature: (name: string) => void;
    disableFeature: (name: string) => void;
  }
}

const isDev =
  window.location.hostname === "localhost" ||
  window.location.hostname.startsWith("dev.");

const key = (name: string) => `feature-${name}`;

const checkFeature = (name: string) => !!localStorage.getItem(key(name));

window.enableFeature = (name: string) => {
  localStorage.setItem(key(name), "true");
};

window.disableFeature = (name: string) => {
  localStorage.removeItem(key(name));
};

const features = {
  pdfviewer: checkFeature("pdfviewer"),
  accounts: checkFeature("accounts") || isDev,
} as const;

export default features;
