import { RefObject, useEffect, useState } from "react";

type Dimensions = {
  width: number;
  height: number;
};

const getSizeAdjustedToAspectRatio = (
  sourceWidth: number,
  sourceHeight: number,
  dWidth: number,
  dHeight: number
) => {
  const ratio = Math.min(dWidth / sourceWidth, dHeight / sourceHeight);

  return {
    height: sourceHeight * ratio,
    width: sourceWidth * ratio,
    ratio,
  };
};

const useImageDimensions = (src: string, ref: RefObject<HTMLElement>) => {
  const [imageDimensions, setImageDimensions] = useState<Dimensions>();

  useEffect(() => {
    const getImageDimensions = () => {
      const i = new Image();

      i.onload = () => {
        if (!ref.current) return;

        setImageDimensions({
          width: i.width,
          height: i.height,
        });
      };

      i.src = src;
    };

    getImageDimensions();

    // TODO - resize Sketch
    // window.addEventListener("resize", getImageDimensions);
    // return () => {
    //   window.removeEventListener("resize", getImageDimensions);
    // };
  }, [src, ref]);

  return imageDimensions && ref.current
    ? getSizeAdjustedToAspectRatio(
        imageDimensions.width,
        imageDimensions.height,
        ref.current.clientWidth,
        ref.current.clientHeight
      )
    : undefined;
};

export default useImageDimensions;
