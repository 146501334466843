import React, { useCallback, useRef, useState } from "react";
import CanvasDraw from "react-canvas-draw";
import toImageDataUrl from "./toImageDataUrl";
import Controls from "./Controls";
import { defaultBrushColor, defaultBrushSize } from "./constants";
import useImageDimensions from "./useImageDimensions";
import { Sketch } from "../../types";

type Props = {
  onChange: (d: Omit<Sketch, "id">) => void;
  image: string;
  saveData?: string;
};

const SketchComponent: React.FC<Props> = ({ onChange, image, saveData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const ref = useRef<CanvasDraw>(null);
  const [brushColor, setBrushColor] = useState<string>(defaultBrushColor.value);
  const [brushRadius, setBrushRadius] = useState<number>(
    defaultBrushSize.value
  );

  const dimensions = useImageDimensions(image, containerRef);

  const handleOnChange = useCallback(
    ({ canvas, getSaveData }: CanvasDraw) =>
      onChange({
        image: toImageDataUrl(canvas.drawing, canvas.grid),
        data: getSaveData(),
      }),
    [onChange]
  );

  return (
    <div className="is-flex is-fullheight">
      <div
        ref={containerRef}
        className="is-flex is-align-items-center is-flex-grow-1"
      >
        {dimensions && (
          <CanvasDraw
            key={JSON.stringify(dimensions)}
            immediateLoading
            saveData={saveData}
            canvasWidth={dimensions.width}
            canvasHeight={dimensions.height}
            ref={ref}
            imgSrc={image}
            brushColor={brushColor}
            catenaryColor={brushColor}
            brushRadius={brushRadius}
            lazyRadius={0}
            hideGrid
            onChange={handleOnChange}
            className="mx-auto has-border is-relative"
          />
        )}
      </div>
      <Controls
        onClear={() => ref.current?.eraseAll()}
        onUndo={() => ref.current?.undo()}
        brushColor={brushColor}
        brushRadius={brushRadius}
        onChangeBrushColor={setBrushColor}
        onChangeBrushRadius={setBrushRadius}
      />
    </div>
  );
};

export default SketchComponent;
