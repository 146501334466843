import classNames from "classnames";
import { isToday, isYesterday, isThisYear, format, isValid } from "date-fns";
import { ClassNameProp } from "../library/utils";

type MaybeDate = string | Date | null | undefined;

const lang = {
  NEVER: "Never",
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  AT: "at",
} as const;

const friendlyDate = (date: MaybeDate) => {
  if (!date) return lang.NEVER;

  const d = new Date(date);

  if (!isValid(d)) return lang.NEVER;

  if (isToday(d)) return lang.TODAY;

  if (isYesterday(d)) return lang.YESTERDAY;

  if (isThisYear(d)) return format(d, "do MMMM");

  return format(d, "PPP");
};

const friendlyTime = (date: MaybeDate) => {
  if (!date) return lang.NEVER;

  const d = new Date(date);

  if (!isValid(d)) return lang.NEVER;

  return `${friendlyDate(d)} ${lang.AT} ${format(d, "p")}`;
};

export const formatDateTime = (date: MaybeDate) => {
  if (!date) return lang.NEVER;
  const d = new Date(date);
  if (!isValid(d)) return lang.NEVER;
  return d.toLocaleString();
};

export const formatDateTimeForFilename = (date: MaybeDate) => {
  if (!date) {
    throw Error("Cannot format empty date for filename");
  }

  return format(new Date(date), "kkmm-do_MMM_yyyy");
};

type Props = {
  date: MaybeDate;
} & ClassNameProp;

export const FriendlyDate: React.FC<Props> = ({ date, className }) => (
  <span className={classNames(className)} title={formatDateTime(date)}>
    {friendlyDate(date)}
  </span>
);

export const FriendlyTime: React.FC<Props> = ({ date, className }) => (
  <span className={classNames(className)} title={formatDateTime(date)}>
    {friendlyTime(date)}
  </span>
);
