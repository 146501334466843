import React, { PropsWithChildren } from "react";

import { Nav } from "../../library";
import Page from "../Page";

import { FlaggedUserMenu } from "../UserMenu";

const Header: React.FC<PropsWithChildren> = ({ children }) => (
  <Page.Header className="has-background-white">
    <Nav.Start>
      <Nav.MenuToggle />
      <Nav.Item className="is-flex-shrink-1 is-flex-grow-1">
        <div className="is-fullwidth">
          <div className="is-fullwidth">{children}</div>
        </div>
      </Nav.Item>
    </Nav.Start>
    <Nav.End>
      <FlaggedUserMenu />
    </Nav.End>
  </Page.Header>
);

const Main: React.FC<PropsWithChildren> = ({ children }) => (
  <Page.Main>{children}</Page.Main>
);

const Body: React.FC<PropsWithChildren> = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren
>(({ children, ...props }, ref) => (
  <Page.Body ref={ref} className="is-flex-grow-1 is-clipped" {...props}>
    <div className="p-3 pt-5 is-flex is-flex-direction-column is-justify-content-space-between is-flex-grow-1 has-scroll-y">
      {children}
    </div>
  </Page.Body>
));

const Footer: React.FC<PropsWithChildren> = ({ children }) => (
  <Page.Footer className="has-border-top p-4 pb-5 is-flex is-justify-content-space-between">
    {children}
  </Page.Footer>
);

const Navigation: React.FC<PropsWithChildren> = ({ children }) => (
  <Page.Navigation className="has-background-feature has-text-white">
    <div className="is-flex is-justify-content-space-between">
      <p className="has-text-weight-semibold mb-4">CABA Assessment</p>
      <Nav.MenuButton icon="xmark" className="has-text-white" />
    </div>
    {children}
  </Page.Navigation>
);

const Resource: React.FC<PropsWithChildren> = ({ children }) => (
  <Page className="has-background-white">{children}</Page>
);

export default Object.assign(Resource, {
  Header,
  Navigation,
  Main,
  Footer,
  Body,
});
