import { useCallback } from "react";
import {
  useForm,
  UseFormRegister,
  UseFormProps,
  FieldErrors,
  FieldValues,
} from "react-hook-form";

export type FormValidationProps<T extends FieldValues> = {
  register: UseFormRegister<T>;
  errors: FieldErrors<T>;
};

function useFormValidation<T extends FieldValues>(props?: UseFormProps<T>) {
  const {
    handleSubmit,
    formState: { errors, isDirty },
    ...rest
  } = useForm<T>(props);

  // We will be using this form onBlur so we don't want to be calling onValid
  // unless the user actually changed something, so check isDirty
  const handleBlur: typeof handleSubmit = useCallback(
    (onValid) =>
      handleSubmit((data) => {
        if (isDirty) onValid(data);
      }),
    [isDirty, handleSubmit]
  );

  return { handleBlur, handleSubmit, errors, ...rest };
}

export default useFormValidation;
