import { QueryClient } from "@tanstack/react-query";

import {
  AssessmentGroup,
  useCreateAssessmentGroupMutation,
  useGetAssessmentGroupQuery,
} from "../generated";

const newAssessmentGroupsKey = ["NewAssessmentGroups"];

const setDefaultCreateAssessmentGroupMutation = (queryClient: QueryClient) => {
  const removeOptimisticMutation = (id: string) =>
    queryClient.setQueryData<AssessmentGroup[]>(
      newAssessmentGroupsKey,
      (existing = []) => existing.filter((g) => g.id !== id)
    );

  // Create Assessment Group
  queryClient.setMutationDefaults(useCreateAssessmentGroupMutation.getKey(), {
    mutationFn: (data: AssessmentGroup) =>
      useCreateAssessmentGroupMutation.fetcher(data, {})(),
    onMutate: async (getAssessmentGroup: AssessmentGroup) => {
      const queryKey = useGetAssessmentGroupQuery.getKey({
        id: getAssessmentGroup.id,
      });

      // Cancel any current queries
      await queryClient.cancelQueries({ queryKey });

      // Add optimistic assessment
      queryClient.setQueryData(queryKey, { getAssessmentGroup });

      queryClient.setQueryData<AssessmentGroup[]>(
        newAssessmentGroupsKey,
        (existing = []) => [getAssessmentGroup, ...existing]
      );
    },
    onError: (_error, variables) => {
      const queryKey = useGetAssessmentGroupQuery.getKey({ id: variables.id });
      queryClient.setQueryData(queryKey, undefined);

      removeOptimisticMutation(variables.id);
    },
    onSettled: (_data, _error, variables) => {
      const queryKey = useGetAssessmentGroupQuery.getKey({ id: variables.id });
      queryClient.invalidateQueries({ queryKey });

      removeOptimisticMutation(variables.id);
    },
  });
};

export default setDefaultCreateAssessmentGroupMutation;
