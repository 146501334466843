import React, { AllHTMLAttributes } from "react";

import classNames from "classnames";
import { ClassNameProp } from "./utils";

type Props = {
  classes?: classNames.Argument;
  as?: React.ElementType;
};

function htmlElementComponent({
  classes,
  as: Tag = "div",
}: Props = {}): React.FC<ClassNameProp & AllHTMLAttributes<HTMLElement>> {
  return React.forwardRef(({ children, className, ...props }, ref) => (
    <Tag {...props} ref={ref} className={classNames(classes, className)}>
      {children}
    </Tag>
  ));
}

export default htmlElementComponent;
