import classNames from "classnames";
import React, { useCallback, useState } from "react";
import { FieldValues } from "react-hook-form";
import { useFormValidation } from "../../hooks";
import { Form, Icon } from "../../library";
import { Recommendation } from "../../types";
import RemainingCharacters from "../RemainingCharacters";

const MAX_LENGTH = 500;

// Take an array of recommendations and convert it to a nested map
// keyed by the area name and vice-versa.
const converters = {
  fromFieldValues: (data: FieldValues): Recommendation[] =>
    Object.entries(data).map(([area, data]) => ({
      area,
      ...data,
    })),
  toFieldValues: (recommendations: Recommendation[]): FieldValues =>
    Object.fromEntries(recommendations.map((r) => [r.area, r])),
} as const;

type Props = {
  updateRecommendations: (values: Recommendation[]) => void;
  recommendations: Recommendation[];
};

const AssessmentRecommendations: React.FC<Props> = ({
  recommendations,
  updateRecommendations,
}) => {
  const { handleBlur, register, watch } = useFormValidation({
    values: converters.toFieldValues(recommendations),
  });

  const update = useCallback(
    (data: FieldValues) =>
      updateRecommendations(converters.fromFieldValues(data)),
    [updateRecommendations]
  );

  const values = watch();

  const [active, setActive] = useState(recommendations.at(0)?.area);

  return (
    <Form onBlur={handleBlur(update)}>
      {recommendations.map((recommendation) => {
        const isActive = active !== recommendation.area;

        return (
          <div
            key={recommendation.area}
            className="last-has-border-bottom has-border-top"
          >
            <h2
              onClick={() =>
                setActive(isActive ? recommendation.area : undefined)
              }
              className="is-clickable has-text-weight-bold is-flex p-2 is-justify-content-space-between"
            >
              {recommendation.area}
              <Icon icon={isActive ? "chevron-down" : "chevron-up"} />
            </h2>
            <div
              className={classNames("p-2", {
                "is-hidden": isActive,
              })}
            >
              <Form.Field>
                <Form.Label>Postural problems</Form.Label>
                <Form.TextareaUncontrolled
                  {...register(`${recommendation.area}.problem`)}
                  maxLength={MAX_LENGTH}
                />
                <Form.Help>
                  <RemainingCharacters
                    max={MAX_LENGTH}
                    value={values[recommendation.area]?.problem || ""}
                  />
                </Form.Help>
              </Form.Field>

              <Form.Field>
                <Form.Label>
                  Recommendations / required interventions
                </Form.Label>
                <Form.TextareaUncontrolled
                  {...register(`${recommendation.area}.recommendation`)}
                  maxLength={MAX_LENGTH}
                />
                <Form.Help>
                  <RemainingCharacters
                    max={MAX_LENGTH}
                    value={values[recommendation.area]?.recommendation || ""}
                  />
                </Form.Help>
              </Form.Field>
            </div>
          </div>
        );
      })}
    </Form>
  );
};

export default AssessmentRecommendations;
