import classNames from "classnames";
import React, { KeyboardEvent, SyntheticEvent, useCallback } from "react";

import { ClassNameProp, Size, Variant } from "../utils";

export type FormInputProps = ClassNameProp & {
  size?: Size;
  onChange?: (value: string) => void;
  onKeyPress?: (event: KeyboardEvent<HTMLInputElement>) => void;
  onEnter?: () => void;
  value?: string | number;
  type?: "text" | "password" | "email" | "tel" | "number" | "date";
  placeholder?: string;
  variant?: Variant;
  min?: string;
  max?: string;
  maxLength?: number;
  disabled?: boolean;
};

const Input: React.FC<FormInputProps> = ({
  onChange,
  onKeyPress,
  onEnter,
  type = "text",
  variant,
  size,
  disabled = false,
  className,
  ...props
}) => {
  const doOnChange = useCallback(
    (e: SyntheticEvent<HTMLInputElement>) => onChange?.(e.currentTarget.value),
    [onChange]
  );

  const doOnKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        if (onEnter) onEnter();
      }
      if (onKeyPress) onKeyPress(event);
    },
    [onEnter, onKeyPress]
  );

  return (
    <input
      disabled={disabled}
      type={type}
      onInput={doOnChange}
      onKeyDown={doOnKeyDown}
      className={classNames("input", className, {
        [`is-${variant}`]: variant,
        [`is-${size}`]: size,
      })}
      {...props}
    />
  );
};

export default Input;
